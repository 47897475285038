import {
  REQ_CONTEST_LIST,
  CONTEST_LIST_SUCCESS,
  CONTEST_LIST_ERROR,
  CONTEST_ID,
  REQ_CONTEST_DETAILS,
  CONTEST_DETAILS_SUCCESS,
  CONTEST_DETAILS_ERROR,
  REQ_DELETED_CONTEST,
  DELETE_CONTEST_SUCCESS,
  DELETE_CONTEST_ERROR,
  CLEAR_DELETE_CONTEST,
  REQ_CREATE_CONTEST,
  CREATE_CONTEST_SUCCESS,
  CREATE_CONTEST_ERROR,
  REQ_UPDATE_CONTEST,
  UPDATE_CONTEST_SUCCESS,
  UPDATE_CONTEST_ERROR,
  REQ_USER_CONTEST,
  USER_CONTEST_SUCCESS,
  USER_CONTEST_ERROR,
  REQ_APPROVE_CONTEST,
  APPROVE_CONTEST_SUCCESS,
  APPROVE_CONTEST_ERROR,
  ADD_CONTEST_SEARCH,
} from "./actionTypes";

const initialState = {
  contestList: null,
  contestListError: null,
  contestId: null,
  addContestSearch: null,
  contestDetails: null,
  contestDetailsError: null,
  deleteContest: null,
  deleteContestError: null,
  createContest: null,
  createContestError: null,
  updateContest: null,
  updateContestError: null,
  userContest: null,
  userContestError: null,
  userContestToggle: false,
  approvalContest:null,
  approveContestError: null,
  approvalContestToggle: false,
};

const contestData = (state = initialState, action) => {
  switch (action.type) {
    case REQ_CONTEST_LIST:
      state = { ...state };
      break;
    case CONTEST_LIST_SUCCESS:
      state = { ...state, contestList: action.payload };
      break;
    case CONTEST_LIST_ERROR:
      state = { ...state, contestListError: action.payload };
      break;
    case CONTEST_ID:
      state = { ...state, contestId: action.payload };
      break;
    case ADD_CONTEST_SEARCH:
      state = { ...state, addContestSearch: action.payload };
      break;
    case REQ_CONTEST_DETAILS:
      state = { ...state };
      break;
    case CONTEST_DETAILS_SUCCESS:
      state = { ...state, contestDetails: action.payload };
      break;
    case CONTEST_DETAILS_ERROR:
      state = { ...state, contestDetailsError: action.payload };
      break;
    case REQ_DELETED_CONTEST:
      state = { ...state };
      break;
    case DELETE_CONTEST_SUCCESS:
      state = { ...state, deleteContest: action.payload };
      break;
    case DELETE_CONTEST_ERROR:
      state = { ...state, deleteContestError: action.payload };
      break;
    case CLEAR_DELETE_CONTEST:
      state = {
        ...state,
        deleteContest: null,
        deleteContestError: null,
        contestDetailsError: null,
        contestListError: null,
        createContest: null,
        createContestError: null,
        updateContest: null,
        updateContestError: null,
        userContest: null,
        userContestError: null,
        userContestToggle: false,
      };
      break;

    case REQ_CREATE_CONTEST:
      state = { ...state };
      break;
    case CREATE_CONTEST_SUCCESS:
      state = { ...state, createContest: action.payload };
      break;
    case CREATE_CONTEST_ERROR:
      state = { ...state, createContestError: action.payload };
      break;

    case REQ_UPDATE_CONTEST:
      state = { ...state };
      break;
    case UPDATE_CONTEST_SUCCESS:
      state = { ...state, updateContest: action.payload };
      break;
    case UPDATE_CONTEST_ERROR:
      state = { ...state, updateContestError: action.payload };
      break;

    case REQ_USER_CONTEST:
      state = { ...state, userContestToggle: true };
      break;
    case USER_CONTEST_SUCCESS:
      state = {
        ...state,
        userContest: action.payload,
        userContestToggle: false,
      };
      break;
    case USER_CONTEST_ERROR:
      state = {
        ...state,
        userContestError: action.payload,
        userContestToggle: false,
      };
      break;

      case REQ_APPROVE_CONTEST:
      state = { ...state, approvalContestToggle: true };
      break;
    case APPROVE_CONTEST_SUCCESS:
      state = {
        ...state,
        approvalContest: action.payload,
        approvalContestToggle: false,
      };
      break;
    case APPROVE_CONTEST_ERROR:
      state = {
        ...state,
        approveContestError: action.payload,
        approvalContestToggle: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default contestData;
