import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Button, Row, Col, Card, CardBody, Spinner, Alert } from "reactstrap";
import {
  reqChangePW,
  clearDeletePw,
} from "../../store/auth/changePassword/actions";
const Changepw = () => {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const condi = useSelector(
    (state) => state.ChangePassword.changePW?.data?.success
  );
  const message = useSelector(
    (state) => state.ChangePassword.changePW?.data?.message
  );

  useEffect(() => {
    if (condi === 1) {
      setToggle(false);
    } else if (condi === 0) {
      setToggle(false);
    }
  }, [condi, setToggle]);

  const onSubmit = (data) => {
    dispatch(reqChangePW(data));
    setToggle(true)
  };

  const back = () => {
    history.push("/dashboard")
    dispatch(clearDeletePw())
  }
  return (
    <div>
      {toggle && <Spinner color="dark" />}
      <Row>
        <div className="col-12 mt-4">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Change Password</h4>
              {condi === 1 && <Alert color='success'>{message}</Alert>}
              {condi === 0 && <Alert color='danger'>{message}</Alert>}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="form-group">
                  <label className="col-sm-2 col-form-label">
                    Current Password
                  </label>
                  <Col sm={10}>
                    <input
                      className="form-control"
                      {...register("current_password", { required: true })}
                      required
                      type="password"
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-2 col-form-label">
                    New Password
                  </label>
                  <Col sm={10}>
                    <input
                      className="form-control"
                      {...register("password", { required: true })}
                      required
                      type="password"
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-2 col-form-label">
                    Conform Password
                  </label>
                  <Col sm={10}>
                    <input
                      className="form-control"
                      {...register("password_confirmation", { required: true })}
                      required
                      type="password"
                    />
                  </Col>
                </Row>
                <Row>
                  <Button className="m-2" type="submit">
                    Change
                  </Button>
                  <Button
                    className="m-2"
                    onClick={back}
                  >
                    Back
                  </Button>
                </Row>
              </form>
            </CardBody>
          </Card>
        </div>
      </Row>
    </div>
  );
};

export default Changepw;
