import axios from "./axios"

export const bannerApi = (res) => {
    const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
    const access_token = sessionData?.data.token;
    return new Promise((resolve, reject) => {
        axios
          .post(
            "/admin/banner/get_active_entries",
            res,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
}

export const selectedBannerApi = (res) => {
    const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
    const access_token = sessionData?.data.token;
    return new Promise((resolve, reject) => {
        axios
          .post(
            "/admin/banner/select_banner",
            res,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
}


export const bannerListApi = () => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
      axios
        .get(
          "/admin/banner/banner_list",
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
}


export const bannerDeleteApi = (id) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
      axios
        .get(
          `/admin/banner/banner_delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
}


export const bannerTextApi = (data) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
      axios
        .post(
          `/admin/banner/content/edit`,
          data,{
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
}