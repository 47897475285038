import React from "react";
import { Route, Redirect } from "react-router-dom";
// import {useSelector} from "react-redux"
//AUTH related methods

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtect,
  ...rest
}) => {
  const data = JSON.parse(localStorage.getItem("loginInfo"));
  const sessionToken = data?.success;

  console.log({ isAuthProtect, sessionToken });
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtect && !sessionToken) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default AppRoute;
