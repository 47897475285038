import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reqBannerList, reqDeleteBanner } from "../../store/banner/actions";
import { Spinner, Button } from "reactstrap";
import coverImg from "../../assets/images/img-thumb.jpg"

const BannerList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(reqBannerList());
  }, [dispatch]);

  const toggle = useSelector((state) => state.Banner.bannerListToggle);

  const bannerData = useSelector(state => state.Banner.bannerList?.data)

  const deleteBanner = (id) => {
    if (
      window.confirm(
        `Are you sure, you want to remove image from banners?`
      )
    ) {
      dispatch(reqDeleteBanner(id))
    }
  }

  return (
    <div className="container-fluid">
      {toggle && <Spinner color="dark" />}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Banner List</h4>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <table id="tech-companies-1" className="table table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Entry id</th>
                        <th>Image</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bannerData?.map(banner =>  <tr
                       key={banner.id}>
                        <th>{banner?.id}</th>
                        <th>{banner?.entry_id}</th>
                        <th><img style={{width: '5rem', height: 'auto'}} src={banner?.banner_image?.url} onError={e => {e.target.src = coverImg}} alt="banner"/></th>
                        <th>
                          <Button outline color="danger" onClick={() => deleteBanner(banner.id)}>
                            Remove image
                          </Button>
                        </th>
                      </tr>)}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerList;
