import {
  REQ_REG_DATA,
  REGISTERED_USERS,
  REGISTERED_USERS_ERROR,
  PAGE_DECRESE,
  PAGE_INCRESE,
  PAGE_ZERO,
  ADD_SEARCH,
  USER_ID,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  PER_PAGE_ENTRIES,
  CLEAR_ERROR,
  REQ_USER_POINT_WISE, 
  USER_POINT_WISE_SUCCESS,
  USER_POINT_WISE_ERROR,
  REQ_LOGS,
  LOG_SUCCESS,
  LOG_ERROR,
  SOCIAL_PLATFORM, 
  SEARCH_CONDI,
  USER_STATUS,
} from "./actionTypes";

export const reqRegData = (page) => {
  return {
    type: REQ_REG_DATA,
    payload: { page },
  };
};

export const registeredUser = (data) => {
  return {
    type: REGISTERED_USERS,
    payload: data,
  };
};

export const registredUserError = (error) => {
  return {
    type: REGISTERED_USERS_ERROR,
    payload: error,
  };
};

export const incresePage = (res) => {
  return {
    type: PAGE_INCRESE,
    payload: res,
  };
};

export const decresePage = (res) => {
  return {
    type: PAGE_DECRESE,
    payload: res,
  };
};

export const setZero = () => {
  return {
    type: PAGE_ZERO,
  };
};

export const addSearch = (res) => {
  return {
    type: ADD_SEARCH,
    payload: res,
  };
};

export const searchCondi = (res) => {
  return {
    type: SEARCH_CONDI,
    payload: res
  }
}

export const userId = (res) => {
  return {
    type: USER_ID,
    payload: res,
  };
};

export const userStatus = (res) => {
  return {
    type: USER_STATUS,
    payload: res,
  };
};

export const fetchUser = (res) => {
  return {
    type: FETCH_USER,
    payload: { res },
  };
};

export const fetchUserSuccess = (res) => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: res,
  };
};

export const fetchUserError = (res) => {
  return {
    type: FETCH_USER_ERROR,
    payload: res,
  };
};

export const perPageEntries = (res) => {
  return {
    type: PER_PAGE_ENTRIES,
    payload: res,
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
};

export const reqUserPointWise = (res) => {
    return {
        type: REQ_USER_POINT_WISE,
        payload: { res }
    }
}

export const userPointWiseSuccess = (res) => {
    return {
        type: USER_POINT_WISE_SUCCESS,
        payload: res
    }
}

export const userPointWiseError = (res) => {
    return {
        type: USER_POINT_WISE_ERROR,
        payload: res
    }
}

export const reqLogs = (res) => {
  return {
    type: REQ_LOGS,
    payload: { res }
  }
}

export const logSuccess = (res) => {
  return {
    type: LOG_SUCCESS,
    payload: res
  }
}

export const logError = (res) => {
  return {
    type: LOG_ERROR,
    payload: res
  }
}

export const socialPlatform = (res) => {
  return {
    type: SOCIAL_PLATFORM,
    payload: res
  }
}