import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { withRouter, Link } from "react-router-dom";

// users
import user from "../assets/images/logo-color.png";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              style={{ objectFit: "contain" }}
              className="rounded-circle header-profile-user"
              src={user}
              alt="Header Avatar"
            />
          </DropdownToggle>
          <DropdownMenu right>
            <Link to="/change-password" className="dropdown-item">
              <i className="ti-back-right mr-1"></i>
              <span>Change Password</span>
            </Link>
            <Link to="/logout" className="dropdown-item">
              <i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>
              <span>Logout</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withRouter(ProfileMenu);
