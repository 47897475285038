import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Row, Card, CardBody, Col, Button, Spinner } from "reactstrap";
import {
  reqCreateContest,
  clearDeleteContest,
} from "../../store/contest/actions";

const CreateContest = () => {
  const [toggle, setToggle] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, watch } = useForm();

  const privateCode = watch("private");
  const eventPera = watch("halla_type");
  const shareTagWatch = watch("allow_sharing")

  const condi = useSelector(
    (state) => state.Contest.createContest?.data?.success
  );
  const message = useSelector(
    (state) => state.Contest.createContest?.data?.message
  );

  const error = useSelector((state) => state.Contest.createContestError);

  useEffect(() => {
    if (condi === 1) {
      alert(message);
      dispatch(clearDeleteContest());
      history.push("/dashboard");
      setToggle(false);
    } else if (condi === 0) {
      alert(message);
      dispatch(clearDeleteContest());
      setToggle(false);
    } else if (error) {
      alert(error?.message);
      dispatch(clearDeleteContest());
      setToggle(false);
    }
  }, [condi, message, dispatch, history, setToggle, error]);

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("image", data.image[0]);
    formData.append("thumb_image", data.thumb_image[0]);
    formData.append("img_credit", data.img_credit);
    formData.append("img_credit_url", data.img_credit_url);
    formData.append("prize", data.prize);
    formData.append("halla_type", data.halla_type);
    formData.append("type", data.type);
    formData.append("no_winner", data.no_winner);
    formData.append("s_name", data.s_name);
    formData.append("sponsor_logo", data.sponsor_logo[0]);
    formData.append("sponsor_description", data.sponsor_description);
    formData.append("s_url", data.s_url);
    formData.append("s_date", data.s_date);
    formData.append("e_date", data.e_date);
    formData.append("summary", data.summary);
    formData.append("hint", data.hint);
    formData.append("private", data.private);
    formData.append("private_code", data.private_code);
    formData.append("lat", data.lat)
    formData.append("long", data.long)
    formData.append("range", data.range)
    formData.append("allow_sharing", data.allow_sharing);
    formData.append("tag", data.tag);
    formData.append("owner", data.owner);
    formData.append("voting", data.voting);
    formData.append("platform", data.platform);
    formData.append("status", data.status);
    dispatch(reqCreateContest(formData));
    setToggle(true);
  };

  return (
    <div>
      <div className="container-fluid mt-4">
        <Row>
          <div className="col-12">
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Create Contest / Event</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">Title</label>
                    <Col sm={10}>
                      <input
                        className="form-control"
                        {...register("title", { required: true })}
                        type="text"
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">
                      Description
                    </label>
                    <Col sm={10}>
                      <textarea
                        className="form-control"
                        {...register("description", { required: true })}
                        type="text"
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">Image</label>
                    <Col sm={10}>
                      <input
                        type="file"
                        {...register("image", { required: true })}
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">
                      Thumb Image
                    </label>
                    <Col sm={10}>
                      <input
                        type="file"
                        {...register("thumb_image", { required: true })}
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">
                      Image credit
                    </label>
                    <Col sm={10}>
                      <input
                        className="form-control"
                        {...register("img_credit", { required: true })}
                        type="text"
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">
                      Image credit URL
                    </label>
                    <Col sm={10}>
                      <input
                        className="form-control"
                        {...register("img_credit_url", { required: true })}
                        type="text"
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">Prize</label>
                    <Col sm={10}>
                      <input
                        className="form-control"
                        {...register("prize")}
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">Type</label>
                    <Col sm={10}>
                      <select
                        className="form-control"
                        {...register("halla_type")}
                      >
                        <option value="contest">Contest</option>
                        <option value="event">Event</option>
                      </select>
                    </Col>
                  </Row>

                  {eventPera === "event" && (
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Private event
                      </label>
                      <Col sm={10}>
                        <select
                          className="form-control"
                          name="private"
                          {...register("private")}
                        >
                          <option value="0">No</option>
                          <option value="1">Yes</option>
                        </select>
                      </Col>
                    </Row>
                  )}

                  {privateCode === "1" && (
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Private Code
                      </label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("private_code")}
                          required
                          type="text"
                        />
                      </Col>
                    </Row>
                  )}

                  {privateCode === "1" && (
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                      Coordinate
                      </label>
                      <Col sm={5}>
                        <input
                          className="form-control"
                          {...register("lat")}
                          required
                          type="text"
                          placeholder="Latitude"
                        />
                      </Col>
                      <Col sm={5}>
                        <input
                          className="form-control"
                          {...register("long")}
                          required
                          type="text"
                          placeholder="Longitude"
                        />
                      </Col>
                    </Row>
                  )}

                  {privateCode === "1" && (
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">Range</label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("range")}
                          required
                          type="number"
                        />
                      </Col>
                    </Row>
                  )}

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">
                      Contest Type
                    </label>
                    <Col sm={10}>
                      <select
                        className="form-control"
                        {...register("type", { required: true })}
                      >
                        <option value="image">Image</option>
                        <option value="video">Video</option>
                        {/* <option value="both">Both</option> */}
                      </select>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">
                      No. of winner
                    </label>
                    <Col sm={10}>
                      <input
                        className="form-control"
                        {...register("no_winner")}
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">
                      Sponsor Name
                    </label>
                    <Col sm={10}>
                      <input
                        className="form-control"
                        {...register("s_name")}
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">
                      Sponsor Logo
                    </label>
                    <Col sm={10}>
                      <input
                        type="file"
                        {...register("sponsor_logo", { required: true })}
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">
                      Sponsor Description
                    </label>
                    <Col sm={10}>
                      <input
                        className="form-control"
                        {...register("sponsor_description")}
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">
                      Sponsor URL
                    </label>
                    <Col sm={10}>
                      <input
                        className="form-control"
                        {...register("s_url")}
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">
                      Starting Date
                    </label>
                    <Col sm={10}>
                      <input
                        className="form-control"
                        {...register("s_date", { required: true })}
                        type="date"
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">
                      Ending Date
                    </label>
                    <Col sm={10}>
                      <input
                        className="form-control"
                        {...register("e_date", { required: true })}
                        type="date"
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">Summary</label>
                    <Col sm={10}>
                      <input
                        className="form-control"
                        {...register("summary")}
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">Hint</label>
                    <Col sm={10}>
                      <input
                        className="form-control"
                        {...register("hint")}
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">
                      Social sharing
                    </label>
                    <Col sm={10}>
                      <select
                        className="form-control"
                        {...register("allow_sharing")}
                      >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </Col>
                  </Row>

                  {shareTagWatch === "1" && <Row className="form-group">
                    <label className="col-sm-2 col-form-label">
                      Sharing tag
                    </label>
                    <Col sm={10}>
                      <input
                        className="form-control"
                        {...register("tag")}
                        type="text"
                      />
                    </Col>
                  </Row>}

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">Event Owner (Halla id)</label>
                    <Col sm={10}>
                      <input
                        className="form-control"
                        {...register("owner")}
                        type="number"
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">Voting</label>
                    <Col sm={10}>
                      <select className="form-control" {...register("voting")}>
                        <option value="normal">Normal</option>
                        <option value="social">Social</option>
                        <option value="all">All</option>
                      </select>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">Platform</label>
                    <Col sm={10}>
                      <select
                        className="form-control"
                        {...register("platform")}
                      >
                        <option value="website">Website</option>
                        <option value="app">App</option>
                        <option value="mobile">Mobile</option>
                        <option value="all">All</option>
                      </select>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-2 col-form-label">Status</label>
                    <Col sm={10}>
                      <select
                        className="form-control"
                        {...register("status", { required: true })}
                      >
                        <option value="active">Active</option>
                        <option value="passive">Passive</option>
                        <option value="upcoming">Upcoming</option>
                      </select>
                    </Col>
                  </Row>

                  <Row>
                    <Button className="m-2" disabled={toggle} type="submit">
                      Create
                    </Button>
                    <Button
                      className="m-2"
                      onClick={() => history.push("/dashboard")}
                    >
                      Cancel
                    </Button>
                    {toggle && <Spinner color="dark" />}
                  </Row>
                </form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default CreateContest;
