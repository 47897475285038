import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, CardBody, Card, Spinner } from "reactstrap";
import { reqUserEntry } from "../../store/entries/actions";
import { reqContestDetails } from "../../store/contest/actions";
import thumbImg from "../../assets/images/img-thumb.jpg";

const EntryDetails = () => {
  const { entryId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const userEntry = useSelector((state) => state.Entries.userEntry?.data);
  const userEntryErr = useSelector((state) => state.Entries.userEntryErr);
  const type = useSelector((state) => state.Contest.contestDetails?.data?.type);

  useEffect(() => {
    dispatch(reqContestDetails(userEntry?.contest_id));
    dispatch(reqUserEntry(entryId));
  }, [dispatch, entryId, userEntry?.contest_id]);
  return (
    <div>
      {!parseInt(entryId) && <h2>Entry Not Found</h2>}
      {parseInt(entryId) &&
        userEntry?.id !== parseInt(entryId) &&
        !userEntryErr && <Spinner color="dark" />}
      {userEntryErr && (
        <div>
          <Row className="m-2">
            <Button onClick={() => history.goBack()}>Back</Button>
          </Row>
          <h2 className="m-2">{userEntryErr?.message}</h2>
        </div>
      )}
      {parseInt(entryId) && userEntry?.id === parseInt(entryId) && (
        <div className="container-fluid">
          <Row className="m-2">
            <Button onClick={() => history.goBack()}>Back</Button>
          </Row>
          <Row className="align-items-center col-12 justify-content-center">
            {type === "image" ? (
              <img
                src={userEntry?.url}
                onError={e => {e.target.src = thumbImg}}
                alt="user"
                className="m-4"
                style={{ borderRadius: "10px", width: "200px", height: "auto" }}
              />
            ) : type === "video" ? (
              userEntry?.y_link ? (
                <iframe
                  width="200px"
                  height="auto"
                  className="m-4"
                  src={`https://www.youtube.com/embed/${userEntry?.y_link}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={userEntry?.title}
                  style={{
                    borderRadius: "10px",
                  }}
                />
              ) : (
                <video
                  src={userEntry?.url}
                  frameBorder="0"
                  loop
                  controls
                  className="m-4"
                  title={userEntry?.title}
                  style={{
                    width: "200px",
                    height: "auto",
                    borderRadius: "10px",
                  }}
                />
              )
            ) : (
              <img
                src={thumbImg}
                alt="thumb"
                className="m-4"
                style={{ borderRadius: "10px", width: "200px", height: "auto" }}
              />
            )}
          </Row>

          <Row>
            <div className="col-12">
              <Card>
                <CardBody>
                  <h3 className="card-title mb-4">Entry Details</h3>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Title
                    </label>
                    <Col sm={10}>
                      <h5>{userEntry?.title || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Description
                    </label>
                    <Col sm={10}>
                      <h5>{userEntry?.description || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Uploaded At
                    </label>
                    <Col sm={10}>
                      <h5>
                        {new Date(
                          userEntry?.video_uploaded?.concat(" UTC")
                        ).toLocaleString()}
                      </h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Status
                    </label>
                    <Col sm={10}>
                      <h5>{userEntry?.status || "-"}</h5>
                    </Col>
                  </Row>

                  {(type === "video" || type === "both") && (
                    <Row className="form-group align-items-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Youtube Link
                      </label>
                      <Col sm={10}>
                        <h5>{userEntry?.y_link || "-"}</h5>
                      </Col>
                    </Row>
                  )}

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Tweet hashtag
                    </label>
                    <Col sm={10}>
                      <h5>{userEntry?.tweet_hashtag || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Winner
                    </label>
                    <Col sm={10}>
                      <h5>{userEntry?.winner || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Sell
                    </label>
                    <Col sm={10}>
                      <h5>{userEntry?.sell || "-"}</h5>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </div>
      )}
    </div>
  );
};

export default EntryDetails;
