import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form"
import { reqBannerText } from "../../store/banner/actions";

const BannerText = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm()

    const onSubmit = (data) => {
        dispatch(reqBannerText(data))
      }

      const toggle = useSelector((state) => state.Banner.bannerTextToggle)
    return (
        <div>
            {toggle && <Spinner color="dark" />}
        <div className="container-fluid mt-4">
          <Row>
            <div className="col-12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Banner text form</h4>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">Heading</label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("heading", {required: true})}
                          type="text"
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Sub heading
                      </label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("subheading", {required: true})}
                          type="text"
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Button type="submit" className="m-2">
                        Save
                      </Button>
                      <Button className="m-2" onClick={() => history.push("/dashboard")}>
                        Cancel
                      </Button>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </div>
    </div>
    )
}

export default BannerText
