import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLatest,
} from "redux-saga/effects";
import {
  REQ_BANNER,
  REQ_SELECT_BANNER,
  REQ_BANNER_LIST,
  REQ_BANNER_DELETE,
  REQ_BANNER_TEXT
} from "./actionTypes";
import {
  bannerSuccess,
  bannerFailed,
  selectBannerSuccess,
  selectBannerFailed,
  bannerListSuccess,
  bannerListError,
  deleteBannerSuccess,
  deleteBannerError,
  bannerTextSuccess,
  bannerTextError
} from "./actions";
import { searchCondi } from "../data/actions";
import {
  bannerApi,
  selectedBannerApi,
  bannerListApi,
  bannerDeleteApi,
  bannerTextApi
} from "../../helpers/callBanner";

function* fetchBanner({ payload: { res } }) {
  try {
    const response = yield call(bannerApi, res);
    yield put(bannerSuccess(response));
    yield put(searchCondi(false));
  } catch (err) {
    yield put(bannerFailed(err));
  }
}

function* fetchSelectedBanner({ payload: { res } }) {
  try {
    const response = yield call(selectedBannerApi, res);
    yield put(selectBannerSuccess(response));
  } catch (err) {
    yield put(selectBannerFailed(err));
  }
}

function* bannerList() {
  try {
    const response = yield call(bannerListApi);
    yield put(bannerListSuccess(response));
  } catch (err) {
    yield put(bannerListError(err));
  }
}

function* deleteBanner({ payload: res }) {
  try {
    const response = yield call(bannerDeleteApi, res);
    yield put(deleteBannerSuccess(response));
    if (response.success === 1) {
      yield put({ type: REQ_BANNER_LIST });
    }
  } catch (err) {
    yield put(deleteBannerError(err));
  }
}

function* textOfBanner({ payload: res }) {
  try {
    const response = yield call(bannerTextApi, res);
    yield put(bannerTextSuccess(response));
  } catch (err) {
    yield put(bannerTextError(err));
  }
}

export function* watchBanner() {
  yield takeEvery(REQ_BANNER, fetchBanner);
}

export function* watchSelectedBanner() {
  yield takeEvery(REQ_SELECT_BANNER, fetchSelectedBanner);
}

export function* watchBannerList() {
  yield takeLatest(REQ_BANNER_LIST, bannerList);
}

export function* watchDeleteBanner() {
  yield takeLatest(REQ_BANNER_DELETE, deleteBanner);
}

export function* watchTextOfBanner(){
  yield takeLatest(REQ_BANNER_TEXT, textOfBanner)
}

function* BannerSaga() {
  yield all([
    fork(watchBanner),
    fork(watchSelectedBanner),
    fork(watchBannerList),
    fork(watchDeleteBanner),
    fork(watchTextOfBanner)
  ]);
}

export default BannerSaga;
