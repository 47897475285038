import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {
    Row,
    Col,
    Button,
    Spinner,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
  } from "reactstrap";
  import {
    incresePage,
    decresePage,
    perPageEntries,
    reqLogs,
    clearError,
    setZero
  } from "../../store/data/actions";

const Logs = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const page = useSelector((state) => state.UserData.page);
    const perPage = useSelector((state) => state.UserData?.perPage);

    const limit = useSelector((state) => state.UserData.logs?.data?.limit)
    const currentPage = useSelector(
      (state) => state.UserData.logs?.data?.current_page
    );
    const lastPage = useSelector(
      (state) => state.UserData.logs?.data?.last_page
    );

    const logs = useSelector(state => state.UserData.logs)
    const error = useSelector(state => state.UserData.logsErr)


    useEffect(() => {
      dispatch(reqLogs({page: page, limit: perPage}))
      if (currentPage > lastPage) {
        dispatch(setZero());
      }
    }, [dispatch, page, perPage, currentPage , lastPage]);

    const errorHandle = () => {
      alert(error?.message)
      dispatch(clearError())
    }

    return (
        <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center m-3">
            <Col>
              <Row className="align-items-center">
                <Button
                  disabled={page <= 1}
                  onClick={() => dispatch(decresePage(1))}
                >
                  Prev.
                </Button>
                <h6 className="ml-4 mr-4">{currentPage}</h6>
                <Button
                  disabled={page >= lastPage}
                  onClick={() => dispatch(incresePage(1))}
                >
                  Next
                </Button>
                <h6 className="ml-4 mr-4">Total Pages: {lastPage}</h6> 
              </Row>
            </Col>
            <Col>
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret>Per Page - {perPage}</DropdownToggle> 
                <DropdownMenu>
                <DropdownItem onClick={() => dispatch(perPageEntries(5))}>
                  5
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(10))}>
                  10
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(20))}>
                  20
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(50))}>
                  50
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(100))}>
                  100
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(200))}>
                  200
                </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          {((!logs && !error) || (currentPage !== page  || perPage !== limit)) && <Spinner color="dark" />} 
          {error && errorHandle()}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Logs</h4>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <table
                        id="tech-companies-1"
                        className="table table-striped"
                      >
                        <thead>
                          <tr>
                            <th>User Id</th>
                            <th>IP</th>
                            <th>Date</th>
                            <th>Browser</th>
                            <th>Page</th>
                          </tr>
                        </thead>
                        {logs && (
                          <tbody>
                            {(currentPage === page && perPage === limit) && logs.data?.data.map((log) => (
                              <tr key={log.id}>
                                <th>{log.user_id || "--"}</th>
                                <th>{log.ip || "--"}</th>
                                <th>{log.date || "--"}</th>
                                <th>{log.browser || "--"}</th>
                                <th>{log.page || "--"}</th>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
}

export default Logs
