import { REQ_CHANGE_PW, CHANGE_PW_SUCCESS, CHANGE_PW_ERROR, CLEAR_DELETE_PW } from "./actionTypes"

export const reqChangePW = (res) => {
    return {
        type: REQ_CHANGE_PW,
        payload: res
    }
}

export const changePWsuccess = (res) => {
    return {
        type: CHANGE_PW_SUCCESS,
        payload: res
    }
}

export const changePWerror = (res) => {
    return {
        type: CHANGE_PW_ERROR,
        payload: res
    }
}

export const clearDeletePw = () => {
    return {
        type: CLEAR_DELETE_PW
    }
}