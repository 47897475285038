import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { REQ_REG_DATA, FETCH_USER, REQ_USER_POINT_WISE, REQ_LOGS, USER_STATUS } from './actionTypes';
import {registeredUser, registredUserError, fetchUserSuccess, fetchUserError, userPointWiseSuccess, userPointWiseError, logSuccess, logError, searchCondi } from "./actions"
import {registerDataApi, fetchUserInfo, userPointWiseApi, logsApi, userStatusApi} from "../../helpers/callApi"

function* fetchRegistredUser({payload : {page}}) {
    try{
        const response = yield call(registerDataApi, page)
        yield put(registeredUser(response))
        yield put(searchCondi(false));
    }catch(err){
        yield put(registredUserError(err));
    }
}

function* fetchUser({payload}){
    try{
        const response = yield call(fetchUserInfo, payload.res)
        yield put(fetchUserSuccess(response))
    }catch(err){
        yield put(fetchUserError(err));
    }
}

function* fetchUsersPointWise({ payload: {res}}){
    try{
        const response = yield call(userPointWiseApi, res)
        yield put(userPointWiseSuccess(response))
    }catch(err){
        yield put(userPointWiseError(err));
    }
}

function* fetchLogs({ payload: {res}}){
    try{
        const response = yield call(logsApi, res)
        yield put(logSuccess(response))
    }catch(err){
        yield put(logError(err));
    }
}
function* userStatus({ payload: id}){
    try{
        const response = yield call(userStatusApi, id)
        yield put(logSuccess(response))
    }catch(err){
        yield put(logError(err));
    }
}

export function* watchRegisterUser(){
    yield takeEvery(REQ_REG_DATA, fetchRegistredUser)
}

export function* watchFetchUser(){
    yield takeEvery(FETCH_USER, fetchUser)
}

export function* watchUserPointWise(){
    yield takeEvery(REQ_USER_POINT_WISE, fetchUsersPointWise)
}

export function* watchFetchLogs(){
    yield takeEvery(REQ_LOGS , fetchLogs)
}

export function* watchUserStatus(){
    yield takeEvery(USER_STATUS , userStatus)
}

function* dataSaga(){
    yield all([
        fork(watchRegisterUser),
        fork(watchFetchUser),
        fork(watchUserStatus),
        fork(watchUserPointWise),
        fork(watchFetchLogs)
    ])
}

export default dataSaga