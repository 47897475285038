import React, {useState ,useEffect} from "react";
import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form"
import {reqCreateEntry} from "../../store/entries/actions"
import {
  deleteWinner,
} from "../../store/entries/actions";

const CreateEntry = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false)
  const contestId = useSelector((state) => state.Contest.contestId);
  const createEntry = useSelector((state) => state.Entries.createEntry);
  
  const { register, handleSubmit } = useForm()

  useEffect(() => {
    if (createEntry?.data?.success === 1) {
      alert(createEntry?.data?.message);
      dispatch(deleteWinner());
      history.goBack();
      setToggle(false);
    } else if(createEntry?.data?.success === 0) {
      alert(createEntry?.data?.message);
      dispatch(deleteWinner());
      setToggle(false);
    }
  }, [setToggle, createEntry?.data?.message, createEntry?.data?.success, dispatch, history]);

  const onSubmit = newData => {
    let formData = new FormData();
    formData.append('entry_file', newData.entry_file[0])
    formData.append('user_id', newData.user_id)
    formData.append('contest_id', newData.contest_id)
    formData.append('title', newData.title)
    formData.append('description', newData.description)
    dispatch(reqCreateEntry(formData))
    setToggle(true)
  }

  return (
    <div>
        <div className="container-fluid mt-4">
          <Row>
            <div className="col-12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Create Entry</h4>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">User Id</label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("user_id", {required: true})}
                          type="number"
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Contest Id
                      </label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("contest_id", {required: true})}
                          value={contestId}
                          type="number"
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">Title</label>
                      <Col sm={10}>
                        <input
                         
                          type="text"
                          className="form-control"
                          {...register("title", {required: true})}
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Description
                      </label>
                      <Col sm={10}>
                        <textarea
                         
                          className="form-control"
                          {...register("description", {required: true})}
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        file
                      </label>
                      <Col sm={10}>
                        <input
                          type="file"
                          {...register("entry_file", {required: true})}
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Button type="submit" disabled={toggle} className="m-2">
                        Save
                      </Button>
                      <Button className="m-2" onClick={() => history.goBack()}>
                        Cancel
                      </Button>
                      {toggle && <Spinner color="dark"/>}
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </div>
    </div>
  );
};

export default CreateEntry;
