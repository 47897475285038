import React, { useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
// Custom Scrollbar

import { reqDashboardData } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png";
import servicesIcon3 from "../../assets/images/services-icon/03.png";

// Charts

import Doughnut from "../AllCharts/echart1/doughnutchart";

import "chartist/dist/scss/chartist.scss";

const Dashboard = () => {
  const dispatch = useDispatch();
  const registredUsers = useSelector(
    (state) => state.Login?.dashboardData?.data?.ru_users
  );
  const totalEntries = useSelector(
    (state) => state.Login?.dashboardData?.data?.te_users
  );
  const todayUser = useSelector(
    (state) => state.Login?.dashboardData?.data?.tn_users
  );
  const weeklyUser = useSelector(
    (state) => state.Login?.dashboardData?.data?.wn_users
  );
  const monthlyUser = useSelector(
    (state) => state.Login?.dashboardData?.data?.mn_users
  );

  useEffect(() => {
    dispatch(reqDashboardData());
  }, [dispatch]);
  return (
    <React.Fragment>
      <div className="container-fluid">
        <Row className="align-items-center">
          <Col sm={6}>
            <div className="page-title-box">
              <h4 className="font-size-18">Dashboard</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item active">Welcome to Halla.in</li>
              </ol>
            </div>
          </Col>
        </Row>

        <Row style={{display:'flex', justifyContent: 'space-evenly'}}>
    
          <Col xl={6} md={6}>
            <Card className="mini-stat bg-primary text-white" style={{borderRadius: '30px'}}>
              <CardBody>
                <div className="m-4">
                  <div className="float-left mini-stat-img mr-5">
                    <img src={servicesIcon3} alt="" />
                  </div>
                  <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                    Registered Users
                  </h5>
                  <h4 className="font-weight-medium font-size-24">
                    {registredUsers || "-"}{" "}
                  </h4>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={6} md={6}>
            <Card className="mini-stat bg-primary text-white" style={{borderRadius: '30px'}}>
              <CardBody>
                <div className="m-4">
                  <div className="float-left mini-stat-img mr-5">
                    <img src={servicesIcon1} alt="" />
                  </div>
                  <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                    Total Entries
                  </h5>
                  <h4 className="font-weight-medium font-size-24">
                    {totalEntries || "-"}{" "}
                  </h4>
                </div>
              </CardBody>
            </Card>
          </Col>
        
        </Row>
        <Row>
          <Col sm={4}>
            <Card className="bg-dark" style={{borderRadius: '30px'}}>
              <CardBody>
                <div className="text-center text-white py-2">
                  <h5 className="mt-0 mb-4 text-white-50 font-size-16">
                    Total New Users Today
                  </h5>
                  <h1>{todayUser}</h1>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col sm={4}>
            <Card className="bg-dark" style={{borderRadius: '30px'}}>
              <CardBody>
                <div className="text-center text-white py-2">
                  <h5 className="mt-0 mb-4 text-white-50 font-size-16">
                    Total New Users Week
                  </h5>
                  <h1>{weeklyUser}</h1>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col sm={4}>
            <Card className="bg-dark" style={{borderRadius: '30px'}}>
              <CardBody>
                <div className="text-center text-white py-2">
                  <h5 className="mt-0 mb-4 text-white-50 font-size-16">
                    Total New Users Month
                  </h5>
                  <h1>{monthlyUser}</h1>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xl={12}>
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">
                  Device Entry for Participants
                </h4>
                <div id="ct-donut" className="ct-chart wid pt-4">
                  <Doughnut />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
