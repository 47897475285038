import axios from "./axios";

export const loginUserss = (email, password) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/admin/login", {
        user_email: email,
        password,
      })
      .then((res) => {
        resolve(res.data);
        localStorage.setItem("loginInfo", JSON.stringify(res.data));
      })
      .catch((err) => reject(err));
  });
};

export const dashboardApi = () => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
    axios
      .get("/admin/dashboard", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const registerDataApi = (res) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
    axios
      .post("/admin/users", res, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const fetchUserInfo = (userId) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
    axios
      .get(`admin/users/get/${userId}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const logout = () => {
  return new Promise((resolve) => {
    resolve(true);
    sessionStorage.clear();
  });
};

export const userPointWiseApi = (res) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
    axios
      .post("/admin/users/user_points", res, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const changePwApi = (res) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
    axios
      .post("/admin/change_password", res, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const logsApi = (res) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
    axios
      .post("/admin/log", res, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
export const userStatusApi = (res) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
    axios
      .post(
        "/admin/users/status",
        { id: res },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
