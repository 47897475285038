import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";
import { connect } from "react-redux";
import { PAGE_ZERO } from "../../store/data/actionTypes";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
          }
        }
      }
    }
    return false;
  };
  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
              >
                <div id="navigation">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/dashboard">
                        <i className="ti-home mr-2"></i>Dashboard
                      </Link>
                    </li>

                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ CompoState: !this.state.CompoState });
                        }}
                      >
                        <i className="ti-user mr-2"></i> Users
                      </Link>

                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.CompoState,
                        })}
                      >
                        <Link
                          to="/registred-users"
                          onClick={this.props.setZero}
                          className="dropdown-item"
                        >
                          Registred Users
                        </Link>
                        <Link
                          to="/facebook-users"
                          onClick={this.props.setZero}
                          className="dropdown-item"
                        >
                          Facebook Users
                        </Link>
                        <Link
                          to="/google-users"
                          onClick={this.props.setZero}
                          className="dropdown-item"
                        >
                          Google Users
                        </Link>
                        <Link
                          to="/email-users"
                          onClick={this.props.setZero}
                          className="dropdown-item"
                        >
                          Email Users
                        </Link>
                        <Link
                          to="/unsubscribe-users"
                          onClick={this.props.setZero}
                          className="dropdown-item"
                        >
                          Unsubscribe Users
                        </Link>
                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ uiState: !this.state.uiState });
                        }}
                      >
                        <i className="ti-palette mr-2"></i> Contests / Event
                      </Link>

                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.uiState,
                        })}
                      >
                        <Link
                          to="/contest-list"
                          onClick={this.props.setZero}
                          className="dropdown-item"
                        >
                          Contest / Event List
                        </Link>
                        <Link
                          to="/active-contest"
                          onClick={this.props.setZero}
                          className="dropdown-item"
                        >
                          Active Contests
                        </Link>
                        <Link
                          to="/contest-by-user"
                          onClick={this.props.setZero}
                          className="dropdown-item"
                        >
                          User Contests
                        </Link>
                        <Link to="/create-contest" className="dropdown-item">
                        Create Contest / Event
                        </Link>
                      </div>
                    </li>

                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/entries"
                        onClick={this.props.setZero}
                      >
                        <i className="ti-marker mr-2"></i>Entries
                      </Link>
                    </li>

                    <li className="nav-item dropdown mega-dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            ExtraState: !this.state.ExtraState,
                          });
                        }}
                      >
                        <i className="ti-image mr-2"></i> Banner
                      </Link>

                      <div
                        className={classname(
                          "dropdown-menu mega-dropdown-menu dropdown-mega-menu-lg",
                          {
                            show: this.state.ExtraState,
                          }
                        )}
                      >
                        <div className="row">
                          <div className="col-lg-6">
                            <div>
                              <Link to="/banner" className="dropdown-item">
                                Select banner
                              </Link>
                              <Link to="/banner-list" className="dropdown-item">
                                Banner list
                              </Link>
                              <Link to="/banner-form" className="dropdown-item">
                                Banner form
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/user-point-wise"
                        onClick={this.props.setZero}
                      >
                        <i className="ti-bar-chart mr-2"></i>User Point Wise
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/logs"
                        onClick={this.props.setZero}
                      >
                        <i className="ti-file mr-2"></i>Logs
                      </Link>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {
  return {
    setZero: () => {
      dispatch({ type: PAGE_ZERO });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
