import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  incresePage,
  decresePage,
  perPageEntries,
  searchCondi,
} from "../../store/data/actions";
import {
  addEntrySearch,
  deleteWinner,
  entryId,
  reqUserEntry,
  reqEntryDelete,
} from "../../store/entries/actions";
import { reqContestDetails } from "../../store/contest/actions";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import thumbImg from "../../assets/images/img-thumb.jpg";

const EntryTable = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const page = useSelector((state) => state.UserData.page);
  const searchToggle = useSelector((state) => state.UserData.searchCondi);
  const perPage = useSelector((state) => state.UserData?.perPage);
  const search = useSelector((state) => state.Entries.entrySearch);
  const currentPage = useSelector(
    (state) => state.Entries.sapEntries?.data?.current_page
  );
  const lastPage = useSelector(
    (state) => state.Entries.sapEntries?.data?.last_page
  );
  const limit = useSelector((state) => state.Entries.sapEntries?.data?.limit);
  const EntryList = useSelector((state) => state.Entries.sapEntries);
  const error = useSelector((state) => state.Entries.sapEntriesErr);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    if (data.search !== search) {
      dispatch(searchCondi(true));
      dispatch(addEntrySearch(data.search));
    }
  };

  const errorHandle = () => {
    alert(error?.message);
    dispatch(deleteWinner());
  };

  const updateEntry = (entry) => {
    dispatch(entryId(entry.id));
    dispatch(reqContestDetails(entry.contest_id));
    dispatch(reqUserEntry(entry.id));
    history.push("/update-entry");
  };

  const deleteEntries = (entry) => {
    if (
      window.confirm(
        `Are you sure, you want to delele entry submitted by ${entry?.user?.username}?`
      )
    ) {
      dispatch(reqEntryDelete(entry.id));
    }
  };

  const viewEntry = (id) => {
    dispatch(entryId(id));
    history.push(`/entry-details/${id}`);
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <Row className="align-items-center m-3">
          <Col>
            <Row className="align-items-center">
              <Button
                disabled={page <= 1}
                onClick={() => dispatch(decresePage(1))}
              >
                Prev.
              </Button>
              <h6 className="ml-4 mr-4">{currentPage}</h6>
              <Button
                disabled={page >= lastPage}
                onClick={() => dispatch(incresePage(1))}
              >
                Next
              </Button>
              <h6 className="ml-4 mr-4">Total Pages: {lastPage}</h6>
            </Row>
          </Col>
          <Col>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret>Per Page - {perPage}</DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => dispatch(perPageEntries(5))}>
                  5
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(10))}>
                  10
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(20))}>
                  20
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(50))}>
                  50
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(100))}>
                  100
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(200))}>
                  200
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col>
            <form
              className="float-right d-md-flex"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input
                className="form-control"
                type="text"
                {...register("search")}
                defaultValue={search}
                placeholder="search"
              />
              <Button className="ml-2" color="info" outline type="submit">
                Search
              </Button>
            </form>
          </Col>
        </Row>
        {((!EntryList && !error) ||
          currentPage !== page ||
          perPage !== limit ||
          searchToggle) && <Spinner color="dark" />}
        {error && errorHandle()}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Entries List</h4>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <table
                      id="tech-companies-1"
                      className="table table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Title</th>
                          <th>User</th>
                          <th>Contest Name</th>
                          <th>Status</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      {EntryList && (
                        <tbody>
                          {currentPage === page &&
                            perPage === limit &&
                            !searchToggle &&
                            EntryList?.data?.data.map((entry) => (
                              <tr key={entry.id}>
                                <th>
                                  {entry.contest?.type === "image" ? (
                                    <img
                                      className="mr-2"
                                      src={entry.url || thumbImg}
                                      loading="lazy"
                                      alt="user"
                                      style={{
                                        width: "100px",
                                        height: "auto",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  ) : entry.contest?.type === "video" ? (
                                    entry.y_link ? (
                                      <iframe
                                        width="100px"
                                        height="auto"
                                        src={`https://www.youtube.com/embed/${entry.y_link}`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="Embedded youtube"
                                        style={{
                                          borderRadius: "10px",
                                        }}
                                      />
                                    ) : (
                                      <video
                                        src={entry?.url}
                                        frameBorder="0"
                                        loop
                                        controls
                                        title={entry?.title}
                                        style={{
                                          width: "100px",
                                          height: "auto",
                                          borderRadius: "10px",
                                        }}
                                      />
                                    )
                                  ) : // <img
                                  //   className="mr-2"
                                  //   src={`https://img.youtube.com/vi/${entry.y_link}/mqdefault.jpg`}
                                  //   loading="lazy"
                                  //   alt="user"

                                  // />
                                  entry.contest?.type === "both" ? (
                                    <div>
                                      <img
                                        className="mr-2"
                                        src={entry.url || thumbImg}
                                        loading="lazy"
                                        alt="user"
                                        style={{
                                          width: "100px",
                                          height: "auto",
                                          borderRadius: "10px",
                                        }}
                                      />
                                      <img
                                        className="mr-2"
                                        src={`https://img.youtube.com/vi/${entry.y_link}/mqdefault.jpg`}
                                        loading="lazy"
                                        alt="user"
                                        style={{
                                          width: "100px",
                                          height: "auto",
                                          borderRadius: "10px",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      className="mr-2"
                                      src={thumbImg}
                                      loading="lazy"
                                      alt="user"
                                      style={{
                                        width: "100px",
                                        height: "auto",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  )}
                                </th>
                                <th>{entry.title || "--"}</th>
                                <th>
                                  {entry.user?.username ? (
                                    <Link
                                      to={`/user-details/${entry.user?.id}`}
                                      target="_blank"
                                    >
                                      {entry.user?.username}
                                    </Link>
                                  ) : (
                                    <>--</>
                                  )}
                                </th>
                                <th>{entry.contest?.title || "--"}</th>
                                <th>{entry.status || "--"}</th>
                                <th>
                                  <Button
                                    className="ml-2"
                                    outline
                                    color="info"
                                    onClick={() => viewEntry(entry.id)}
                                  >
                                    Entry Details
                                  </Button>
                                  <Button
                                    className="ml-2"
                                    outline
                                    color="info"
                                    onClick={() => updateEntry({ ...entry })}
                                  >
                                    <i className="ti-pencil-alt"></i>
                                  </Button>
                                  <Button
                                    className="ml-2"
                                    outline
                                    color="danger"
                                    onClick={() => deleteEntries({ ...entry })}
                                  >
                                    <i className="ti-trash"></i>
                                  </Button>
                                </th>
                              </tr>
                            ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EntryTable;
