import {
  REQ_CONTEST_LIST,
  CONTEST_LIST_SUCCESS,
  CONTEST_LIST_ERROR,
  CONTEST_ID,
  REQ_CONTEST_DETAILS,
  CONTEST_DETAILS_SUCCESS,
  CONTEST_DETAILS_ERROR,
  REQ_DELETED_CONTEST,
  DELETE_CONTEST_SUCCESS,
  DELETE_CONTEST_ERROR,
  CLEAR_DELETE_CONTEST,
  REQ_CREATE_CONTEST,
  CREATE_CONTEST_SUCCESS,
  CREATE_CONTEST_ERROR,
  REQ_UPDATE_CONTEST,
  UPDATE_CONTEST_SUCCESS,
  UPDATE_CONTEST_ERROR,
  REQ_USER_CONTEST,
  USER_CONTEST_SUCCESS,
  USER_CONTEST_ERROR,
  REQ_APPROVE_CONTEST,
  APPROVE_CONTEST_SUCCESS,
  APPROVE_CONTEST_ERROR,
  ADD_CONTEST_SEARCH
} from "./actionTypes";

export const reqContestList = (res) => {
  return {
    type: REQ_CONTEST_LIST,
    payload: { res },
  };
};

export const contestListSuccess = (res) => {
  return {
    type: CONTEST_LIST_SUCCESS,
    payload: res,
  };
};

export const contestListError = (res) => {
  return {
    type: CONTEST_LIST_ERROR,
    payload: res,
  };
};

export const contestId = (res) => {
  return {
    type: CONTEST_ID,
    payload: res,
  };
};

export const reqContestDetails = (res) => {
  return {
    type: REQ_CONTEST_DETAILS,
    payload: { res },
  };
};

export const contestDetailsSuccess = (res) => {
  return {
    type: CONTEST_DETAILS_SUCCESS,
    payload: res,
  };
};

export const contestDetailsError = (res) => {
  return {
    type: CONTEST_DETAILS_ERROR,
    payload: res,
  };
};

export const reqDeleteContest = (res) => {
  return {
    type: REQ_DELETED_CONTEST,
    payload: { res },
  };
};

export const deleteContestSuccess = (res) => {
  return {
    type: DELETE_CONTEST_SUCCESS,
    payload: res,
  };
};

export const deleteContestError = (res) => {
  return {
    type: DELETE_CONTEST_ERROR,
    payload: res,
  };
};

export const clearDeleteContest = () => {
  return {
    type: CLEAR_DELETE_CONTEST,
  };
};

export const reqCreateContest = (res) => {
  return {
    type: REQ_CREATE_CONTEST,
    payload: res
  }
}

export const createContestSuccess = (res) => {
  return {
    type: CREATE_CONTEST_SUCCESS,
    payload: res
  }
}

export const createContestError = (res) => {
  return {
    type : CREATE_CONTEST_ERROR,
    payload: res
  }
}


export const reqUpdateContest = (res) => {
  return {
    type: REQ_UPDATE_CONTEST,
    payload: res
  }
}

export const updateContestSuccess = (res) => {
  return {
    type: UPDATE_CONTEST_SUCCESS,
    payload: res
  }
}

export const updateContestError = (res) => {
  return {
    type : UPDATE_CONTEST_ERROR,
    payload: res
  }
}

export const addContestSearch = (res) => {
  return {
    type: ADD_CONTEST_SEARCH,
    payload: res,
  };
};

export const reqUserContest = (res) => {
  return {
    type:  REQ_USER_CONTEST,
    payload: res
  }
}
export const userContestSuccess = (res) => {
  return {
    type: USER_CONTEST_SUCCESS, 
    payload: res
  }
}
export const userContestError = (res) => {
  return {
    type: USER_CONTEST_ERROR, 
    payload: res
  }
}


export const reqApproveContest = (res, history) => {
  return {
    type:  REQ_APPROVE_CONTEST,
    payload: res, history
  }
}
export const approveContestSuccess = (res) => {
  return {
    type: APPROVE_CONTEST_SUCCESS, 
    payload: res
  }
}
export const approveContestError = (res) => {
  return {
    type: APPROVE_CONTEST_ERROR, 
    payload: res
  }
}


