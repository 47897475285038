export const REQ_GET_ENTRIES = 'REQ_GET_ENTRIES'
export const GET_ENTRIES_SUCCESS = 'GET_ENTRIES_SUCCESS'
export const GET_ENTRIES_ERROR = 'GET_ENTRIES_ERROR'

export const REQ_ALL_GET_ENTRIES = 'REQ_ALL_GET_ENTRIES'
export const GET_ALL_ENTRIES_SUCCESS = 'GET_ALL_ENTRIES_SUCCESS'
export const GET_ALL_ENTRIES_ERROR = 'GET_ALL_ENTRIES_ERROR'

export const REQ_WINNER = "REQ_WINNER"
export const WINNER_SUCCESS = "WINNER_SUCCESS"
export const WINNER_ERROR ="WINNER_ERROR"
export const DELETE_WINNER = "DELETE_WINNER"


export const REQ_USER_ENTRY = "REQ_USER_ENTRY"
export const USER_ENTRY_SUCCESS = "USER_ENTRY_SUCCESS"
export const USER_ENTRY_ERROR = "USER_ENTRY_ERROR"

export const ENTRY_ID = "ENTRY_ID"

export const REQ_ENTRY_DELETE = "REQ_ENTRY_DELETE"
export const DELETE_ENTRY_SUCCESS = "DELETE_ENTRY_SUCCESS"
export const DELETE_ENTRY_ERROR = "DELETE_ENTRY_ERROR"


export const REQ_UPDATE_ENTRY = 'REQ_UPDATE_ENTRY'
export const UPDATE_ENTRY_SUCCESS = "UPDATE_ENTRY_SUCCESS"
export const UPDATE_ENTRY_ERROR = 'UPDATE_ENTRY_ERROR'


export const REQ_CREATE_ENTRY = 'REQ_CREATE_ENTRY'
export const CREATE_ENTRY_SUCCESS = 'CREATE_ENTRY_SUCCESS'
export const CREATE_ENTRY_FAILURE = 'CREATE_ENTRY_FAILURE'

export const ADD_ENTRY_SEARCH = "ADD_ENTRY_SEARCH"

export const REQ_SAP_ENTRIES = 'REQ_SAP_ENTRIES'
export const SAP_ENTRIES_SUCCESS = 'SAP_ENTRIES_SUCCESS'
export const SAP_ENTRIES_ERR = 'SAP_ENTRIES_ERR'