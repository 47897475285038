import { LOGIN_USER ,LOGIN_SUCCESS, REQ_DASHBOARD_DATA, LOGOUT_USER, LOGOUT_USER_SUCCESS, API_ERROR, DASHBOARD_DATA, DASHBOARD_DATA_ERROR } from './actionTypes';

export const loginUser = (user, history) => {
    return {
        type: LOGIN_USER,
        payload: { user, history }
    }
}

export const loginSuccess = (user) => {
    return {
        type: LOGIN_SUCCESS,
        payload: user
    }
}

export const logoutUser = (history) => {
    return {
        type: LOGOUT_USER,
        payload: { history }
    }
}

export const reqDashboardData = (data) => {
    return {
        type: REQ_DASHBOARD_DATA,
        payload: data
    }
}

export const dashboardData = (data) => {
    return {
        type: DASHBOARD_DATA,
        payload: data
    }
}
export const dashboardDataError = (error) => {
    return {
        type: DASHBOARD_DATA_ERROR,
        payload: error 
    }
}

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {}
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}
