import {
  REQ_GET_ENTRIES,
  GET_ENTRIES_SUCCESS,
  GET_ENTRIES_ERROR,
  REQ_WINNER,
  WINNER_SUCCESS,
  WINNER_ERROR,
  DELETE_WINNER,
  REQ_USER_ENTRY,
  USER_ENTRY_SUCCESS,
  USER_ENTRY_ERROR,
  ENTRY_ID,
  REQ_ENTRY_DELETE,
  DELETE_ENTRY_SUCCESS,
  DELETE_ENTRY_ERROR,
  REQ_UPDATE_ENTRY,
  UPDATE_ENTRY_SUCCESS,
  UPDATE_ENTRY_ERROR,
  REQ_ALL_GET_ENTRIES,
  GET_ALL_ENTRIES_SUCCESS,
  GET_ALL_ENTRIES_ERROR,
  REQ_CREATE_ENTRY,
  CREATE_ENTRY_SUCCESS,
  CREATE_ENTRY_FAILURE,
  ADD_ENTRY_SEARCH,
  REQ_SAP_ENTRIES,
  SAP_ENTRIES_SUCCESS,
  SAP_ENTRIES_ERR,
} from "./actionTypes";

const initialState = {
  participantsEntries: null,
  entriesError: null,
  allParticipantsEntries: null,
  allEntriesError: null,
  contestWinner: null,
  entrySearch: null,
  contestWinnerError: null,
  userEntry: null,
  userEntryErr: null,
  entryId: null,
  entryDelete: null,
  entryDeleteErr: null,
  updateEntry: null,
  updateEntryErr: null,
  createEntry: null,
  createEntryFailure: null,
  sapEntries: null,
  sapEntriesErr: null,
};

const entires = (state = initialState, action) => {
  switch (action.type) {
    case REQ_GET_ENTRIES:
      state = { ...state };
      break;
    case GET_ENTRIES_SUCCESS:
      state = { ...state, participantsEntries: action.payload };
      break;
    case GET_ENTRIES_ERROR:
      state = { ...state, entriesError: action.payload };
      break;

    case REQ_ALL_GET_ENTRIES:
      state = { ...state };
      break;
    case GET_ALL_ENTRIES_SUCCESS:
      state = { ...state, allParticipantsEntries: action.payload };
      break;
    case GET_ALL_ENTRIES_ERROR:
      state = { ...state, allEntriesError: action.payload };
      break;

    case REQ_WINNER:
      state = { ...state };
      break;
    case WINNER_SUCCESS:
      state = { ...state, contestWinner: action.payload };
      break;
    case WINNER_ERROR:
      state = { ...state, contestWinnerError: action.payload };
      break;
    case DELETE_WINNER:
      state = {
        ...state,
        contestWinner: null,
        contestWinnerError: null,
        entryDelete: null,
        entryDeleteErr: null,
        updateEntry: null,
        updateEntryErr: null,
        createEntry: null,
        createEntryFailure: null,
        sapEntries: null,
        sapEntriesErr: null,
      };
      break;
    case REQ_USER_ENTRY:
      state = { ...state };
      break;
    case USER_ENTRY_SUCCESS:
      state = { ...state, userEntry: action.payload };
      break;
    case USER_ENTRY_ERROR:
      state = { ...state, userEntryErr: action.payload };
      break;
    case ENTRY_ID:
      state = { ...state, entryId: action.payload };
      break;

    case REQ_ENTRY_DELETE:
      state = { ...state };
      break;
    case DELETE_ENTRY_SUCCESS:
      state = { ...state, entryDelete: action.payload };
      break;
    case DELETE_ENTRY_ERROR:
      state = { ...state, entryDeleteErr: action.payload };
      break;

    case REQ_UPDATE_ENTRY:
      state = { ...state };
      break;
    case UPDATE_ENTRY_SUCCESS:
      state = { ...state, updateEntry: action.payload };
      break;
    case UPDATE_ENTRY_ERROR:
      state = { ...state, updateEntryErr: action.payload };
      break;

    case REQ_CREATE_ENTRY:
      state = { ...state };
      break;
    case CREATE_ENTRY_SUCCESS:
      state = { ...state, createEntry: action.payload };
      break;
    case CREATE_ENTRY_FAILURE:
      state = { ...state, createEntryFailure: action.payload };
      break;

    case ADD_ENTRY_SEARCH:
      state = { ...state, entrySearch: action.payload };
      break;

    case REQ_SAP_ENTRIES:
      state = { ...state };
      break;
    case SAP_ENTRIES_SUCCESS:
      state = { ...state, sapEntries: action.payload };
      break;
    case SAP_ENTRIES_ERR:
      state = { ...state, sapEntriesErr: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default entires;
