import {
  SUBMIT_INPUT,
  REQ_BANNER,
  BANNER_SUCCESS,
  BANNER_FAILURE,
  REQ_SELECT_BANNER,
  SELECT_BANNER_SUCCESS,
  SELECT_BANNER_FAILED,
  DELETE_SELECTED_BANNER,
  REQ_BANNER_LIST,
  BANNER_LIST_SUCCESS,
  BANNER_LIST_ERROR,
  REQ_BANNER_DELETE,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_ERROR,
  REQ_BANNER_TEXT,
  BANNER_TEXT_SUCCESS,
  BANNER_TEXT_ERROR

} from "./actionTypes";

export const submitInput = (res) => {
  return {
    type: SUBMIT_INPUT,
    payload: res,
  };
};

export const reqBanner = (res) => {
  return {
    type: REQ_BANNER,
    payload: { res },
  };
};

export const bannerSuccess = (res) => {
  return {
    type: BANNER_SUCCESS,
    payload: res,
  };
};

export const bannerFailed = (res) => {
  return {
    type: BANNER_FAILURE,
    payload: res,
  };
};

export const reqSelectBanner = (res) => {
  return {
    type: REQ_SELECT_BANNER,
    payload: { res },
  };
};

export const selectBannerSuccess = (res) => {
  return {
    type: SELECT_BANNER_SUCCESS,
    payload: res,
  };
};

export const selectBannerFailed = (res) => {
  return {
    type: SELECT_BANNER_FAILED,
    payload: res,
  };
};

export const deleteSelectedBanner = () => {
  return {
    type: DELETE_SELECTED_BANNER,
  };
};

export const  reqBannerList = () => {
    return {
        type: REQ_BANNER_LIST
    }
}
export const  bannerListSuccess = (res) => {
    return {
        type: BANNER_LIST_SUCCESS,
        payload: res
    }
}
export const  bannerListError = (res) => {
    return {
        type: BANNER_LIST_ERROR,
        payload: res
    }
}

export const reqDeleteBanner = (res) => {
  return {
    type: REQ_BANNER_DELETE,
    payload: res
  }
}
export const deleteBannerSuccess = (res) => {
  return {
    type: DELETE_BANNER_SUCCESS,
    payload: res
  }
}
export const deleteBannerError = (res) => {
  return {
    type: DELETE_BANNER_ERROR,
    payload: res
  }
}

export const reqBannerText = (res) => {
  return {
    type: REQ_BANNER_TEXT,
    payload: res
  }
}
export const bannerTextSuccess = (res) => {
  return {
    type: BANNER_TEXT_SUCCESS,
    payload: res
  }
}
export const bannerTextError = (res) => {
  return {
    type: BANNER_TEXT_ERROR,
    payload: res
  }
}