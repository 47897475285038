import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  REQ_GET_ENTRIES,
  REQ_WINNER,
  REQ_USER_ENTRY,
  REQ_ENTRY_DELETE,
  REQ_UPDATE_ENTRY,
  REQ_ALL_GET_ENTRIES,
  REQ_CREATE_ENTRY,
  REQ_SAP_ENTRIES
} from "./actionTypes";
import {
  getEntriesSuccess,
  getEntriesError,
  getAllEntriesSuccess,
  getAllEntriesError,
  winnerSuccess,
  winnerError,
  userEntrySuccess,
  userEntryError,
  deleteEntrySuccess,
  deleteEntryError,
  updateEntrySuccess,
  updateEntryError,
  createEntrySuccess,
  createEntryFailure,
  sapEntriesSuccess,
  sapEntriesErr
} from "./actions";
import { searchCondi } from "../data/actions"
import {
  participantsEntriesApi,
  winnerApi,
  userEntryApi,
  deleteEntryApi,
  updateEntryApi,
  allParticipantsEntriesApi,
  createEntryApi,
  sapEntriesApi
} from "../../helpers/callEntriesApi";

function* fetchEntries({ payload: { res } }) {
  try {
    const response = yield call(participantsEntriesApi, res);
    yield put(getEntriesSuccess(response));
  } catch (err) {
    yield put(getEntriesError(err));
  }
}

function* fetchAllEntries({ payload: { res } }) {
  try {
    const response = yield call(allParticipantsEntriesApi, res);
    yield put(getAllEntriesSuccess(response));
  } catch (err) {
    yield put(getAllEntriesError(err));
  }
}

function* winner({ payload: { res } }) {
  try {
    const response = yield call(winnerApi, res);
    yield put(winnerSuccess(response));
  } catch (err) {
    yield put(winnerError(err));
  }
}

function* fetchEntry({ payload: res }) {
  try {
    const response = yield call(userEntryApi, res);
    yield put(userEntrySuccess(response));
  } catch (err) {
    yield put(userEntryError(err));
  }
}

function* deleteEntry({ payload: res }) {
  try {
    const response = yield call(deleteEntryApi, res);
    yield put(deleteEntrySuccess(response));
  } catch (err) {
    yield put(deleteEntryError(err));
  }
}

function* updateEntry({ payload: res }) {
  try {
    const response = yield call(updateEntryApi, res);
    yield put(updateEntrySuccess(response));
  } catch (err) {
    yield put(updateEntryError(err));
  }
}

function* createEntry({ payload: res }) {
  try {
    const response = yield call(createEntryApi, res);
    yield put(createEntrySuccess(response));
  } catch (err) {
    yield put(createEntryFailure(err));
  }
}

function* sapEntries({ payload: {res} }) {
  try {
    const response = yield call(sapEntriesApi, res);
    yield put(sapEntriesSuccess(response));
    yield put(searchCondi(false));
  } catch (err) {
    yield put(sapEntriesErr(err));
  }
}

function* watchFetchEntries() {
  yield takeEvery(REQ_GET_ENTRIES, fetchEntries);
}

function* watchAllFetchEntries() {
  yield takeEvery(REQ_ALL_GET_ENTRIES, fetchAllEntries);
}

export function* watchWinner() {
  yield takeEvery(REQ_WINNER, winner);
}

export function* watchUserEntry() {
  yield takeEvery(REQ_USER_ENTRY, fetchEntry);
}

export function* watchDeleteEntry() {
  yield takeEvery(REQ_ENTRY_DELETE, deleteEntry);
}

export function* watchUpdateEntry() {
  yield takeEvery(REQ_UPDATE_ENTRY, updateEntry);
}

export function* watchCreateEntry() {
  yield takeEvery(REQ_CREATE_ENTRY, createEntry);
}

export function* watchSapEntries() {
  yield takeEvery(REQ_SAP_ENTRIES, sapEntries)
}

function* entriesSaga() {
  yield all([
    fork(watchFetchEntries),
    fork(watchAllFetchEntries),
    fork(watchWinner),
    fork(watchUserEntry),
    fork(watchDeleteEntry),
    fork(watchUpdateEntry),
    fork(watchCreateEntry),
    fork(watchSapEntries),
  ]);
}

export default entriesSaga;
