import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  REQ_DASHBOARD_DATA,
  DASHBOARD_DATA,
  DASHBOARD_DATA_ERROR,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  apiData: null,
  dashboardData: null,
  dashboardDataError: {},
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        apiData: action.payload,
        loading: false,
      };
      break;
    case LOGOUT_USER:
      state = { ...state, apiData: {}, dashboardData: {} };
      break;
      case REQ_DASHBOARD_DATA:
        state = { ...state };
        break;
    case DASHBOARD_DATA:
      state = { ...state, dashboardData: action.payload };
      break;
    case DASHBOARD_DATA_ERROR:
      state = { ...state, dashboardDataError: action.payload };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
