import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { REQ_CHANGE_PW } from './actionTypes';
import { changePWsuccess, changePWerror } from './actions';

import {changePwApi} from "../../../helpers/callApi"

function* changePassword({ payload: res }) {
    try {
        const response = yield call(changePwApi , res);
        yield put(changePWsuccess(response));
    } catch (error) {
        yield put(changePWerror(error));
    }
}


export function* watchChangePassword() {
    yield takeEvery(REQ_CHANGE_PW, changePassword)
}

function* cpSaga() {
    yield all([
        fork(watchChangePassword)
    ]);
}

export default cpSaga