import { all } from 'redux-saga/effects';

//public
import AuthSaga from './auth/login/saga';
import LayoutSaga from './layout/saga';
import DataSaga from "./data/saga"
import ContestSaga from "./contest/saga"
import EntrieSaga from "./entries/saga"
import BannerSaga from "./banner/saga"
import ChangePW from "./auth/changePassword/saga"

export default function* rootSaga() {
    yield all([
        //public
        AuthSaga(),
        ChangePW(),
        DataSaga(),
        LayoutSaga(),
        ContestSaga(),
        EntrieSaga(),
        BannerSaga()
    ])
}