import axios from "./axios";

export const contestListApi = (res) => {
    const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
    const access_token = sessionData?.data.token;
    return new Promise((resolve, reject) => {
        axios
          .post(
            "/admin/contests",
            res,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
}


export const contestDetailsApi = (contestId) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/contests/get/${contestId}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}


export const deleteContestApi = (contestId) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/contests/delete/${contestId}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

export const createContestApi = (res) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
      axios
        .post(
          "/admin/contests/create",
           res,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
}

export const updateContestApi = (res) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
      axios
        .post(
          "/admin/contests/update",
           res,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
}


export const userContestApi = (res) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
      axios
        .post(
          "/admin/contests/user-contests-list",
           res,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
}


export const approveContestApi = (res) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
      axios
        .post(
          "/admin/contests/contests-approve-decline",
           res,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
}