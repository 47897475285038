import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import {
  reqContestDetails,
  reqApproveContest,
  reqUpdateContest
} from "../../store/contest/actions";

const ApprovalForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const contestId = useSelector((state) => state.Contest.contestId);
  const contest = useSelector((state) => state.Contest.contestDetails?.data);
  const { register, handleSubmit } = useForm();

  const toggle = useSelector((state) => state.Contest.approvalContestToggle);

  useEffect(() => {
    dispatch(reqContestDetails(contestId));
  }, [dispatch, contestId]);

  const onSubmit = (data) => {
    let obj = {
      contest_id : contestId,
      status : data.status
    }
    if(data.status === "0"){
      dispatch(reqUpdateContest({...contest ,status : "active"}))
      dispatch(reqApproveContest(obj, history));
      return
    } else if (data.status === "1"){
      dispatch(reqUpdateContest({...contest, status : "passive"}))
      dispatch(reqApproveContest(obj, history));
      return
    } else {
      dispatch(reqUpdateContest({...contest, status : "passive"}))
      dispatch(reqApproveContest(obj, history));
    }
  };

  return (
    <div>
      {contestId !== contest?.id && <Spinner color="dark" />}
      {contest && contestId === contest?.id && (
        <div className="container-fluid mt-4">
          <Row>
            <div className="col-12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Approval for user contest</h4>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Approval Status
                      </label>
                      <Col sm={10}>
                        <select
                          className="form-control"
                          {...register("status")}
                        >
                          <option
                            value="0"
                            selected={contest?.is_user_contest_approve === 0}
                          >
                            Approve
                          </option>
                          <option
                            value="1"
                            selected={contest?.is_user_contest_approve === 1}
                          >
                            Pending
                          </option>
                          <option
                            value="2"
                            selected={contest?.is_user_contest_approve === 2}
                          >
                            Decline
                          </option>
                        </select>
                      </Col>
                    </Row>

                    <Row>
                      <Button className="m-2" disabled={toggle} type="submit">
                        Submit
                      </Button>
                      <Button className="m-2" onClick={() => history.goBack()}>
                        Cancel
                      </Button>
                      {toggle && <Spinner color="dark" />}
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ApprovalForm;
