import {
  REGISTERED_USERS,
  REQ_REG_DATA,
  REGISTERED_USERS_ERROR,
  PAGE_INCRESE,
  PAGE_DECRESE,
  PAGE_ZERO,
  ADD_SEARCH,
  USER_ID,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  PER_PAGE_ENTRIES,
  CLEAR_ERROR,
  REQ_USER_POINT_WISE,
  USER_POINT_WISE_SUCCESS,
  USER_POINT_WISE_ERROR,
  REQ_LOGS,
  LOG_SUCCESS,
  LOG_ERROR,
  SOCIAL_PLATFORM,
  SEARCH_CONDI,
} from "./actionTypes";

const initialState = {
  registeredUser: null,
  error: null,
  page: 1,
  search: "",
  searchCondi: false,
  social: "",
  userId: null,
  userDetails: null,
  userDetailsErr: null,
  perPage: 20,
  userPointWise: null,
  userPointWiseErr: null,
  logs: null,
  logsErr: null,
};

const userData = (state = initialState, action) => {
  switch (action.type) {
    case REQ_REG_DATA:
      state = { ...state };
      break;
    case REGISTERED_USERS:
      state = { ...state, registeredUser: action.payload };
      break;
    case REGISTERED_USERS_ERROR:
      state = { ...state, error: action.payload };
      break;
    case PAGE_INCRESE:
      state = { ...state, page: state.page + action.payload };
      break;
    case PAGE_DECRESE:
      state = { ...state, page: state.page - action.payload };
      break;
    case PAGE_ZERO:
      state = { ...state, page: 1 };
      break;
    case ADD_SEARCH:
      state = { ...state, search: action.payload };
      break;
      case SEARCH_CONDI: 
      state = { ...state, searchCondi: action.payload};
      break
    case SOCIAL_PLATFORM:
      state = { ...state, social: action.payload };
      break;
    case USER_ID:
      state = { ...state, userId: action.payload };
      break;
    case FETCH_USER:
      state = { ...state };
      break;
    case FETCH_USER_SUCCESS:
      state = { ...state, userDetails: action.payload };
      break;
    case FETCH_USER_ERROR:
      state = { ...state, userDetailsErr: action.payload };
      break;
    case PER_PAGE_ENTRIES:
      state = { ...state, perPage: action.payload };
      break;
    case CLEAR_ERROR:
      state = {
        ...state,
        error: null,
        userDetailsErr: null,
        userPointWiseErr: null,
        logs: null,
        logsErr: null,
      };
      break;
    case REQ_USER_POINT_WISE:
      state = { ...state };
      break;
    case USER_POINT_WISE_SUCCESS:
      state = { ...state, userPointWise: action.payload };
      break;
    case USER_POINT_WISE_ERROR:
      state = { ...state, userPointWiseErr: action.payload };
      break;

    case REQ_LOGS:
      state = { ...state };
      break;
    case LOG_SUCCESS:
      state = { ...state, logs: action.payload };
      break;
    case LOG_ERROR:
      state = { ...state, logsErr: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default userData;
