export const REGISTERED_USERS = "REGISTERED_USERS"
export const REGISTERED_USERS_ERROR = 'REGISTERED_USERS_ERROR'
export const PAGE_INCRESE = "PAGE_INCRESE"
export const PAGE_DECRESE = "PAGE_DECRESE"
export const REQ_REG_DATA = "REQ_REG_DATA"
export const PAGE_ZERO = "PAGE_ZERO"
export const ADD_SEARCH = "ADD_SEARCH"
export const USER_ID = "USER_ID"
export const USER_STATUS = "USER_STATUS"
export const FETCH_USER = "FETCH_USER"
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS"
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR'
export const PER_PAGE_ENTRIES = "PER_PAGE_ENTRIES"
export const CLEAR_ERROR ="CLEAR_ERROR"
export const REQ_USER_POINT_WISE = "REQ_USER_POINT_WISE"
export const USER_POINT_WISE_SUCCESS = "USER_POINT_WISE_SUCCESS"
export const USER_POINT_WISE_ERROR = "USER_POINT_WISE_ERROR"



export const REQ_LOGS = 'REQ_LOGS'
export const LOG_SUCCESS = 'LOG_SUCCESS'
export const LOG_ERROR = 'LOG_ERROR'

export const SOCIAL_PLATFORM = "SOCIAL_PLATFORM"

export const SEARCH_CONDI = 'SEARCH_CONDI'