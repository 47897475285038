import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reqRegData,
  setZero,
  socialPlatform,
  searchCondi,
} from "../../store/data/actions";
import Tables from "../Tables/UsersTable";

const RegistredUsers = () => {
  const dispatch = useDispatch();
  const page = useSelector((state) => state.UserData.page);
  const search = useSelector((state) => state.UserData.search);
  const lastPage = useSelector(
    (state) => state.UserData.registeredUser?.data?.last_page
  );
  const currentPage = useSelector(
    (state) => state.UserData.registeredUser?.data?.current_page
  );
  const perPage = useSelector((state) => state.UserData?.perPage);
  useEffect(() => {
    dispatch(searchCondi(true));
    dispatch(
      reqRegData({
        page: page,
        registered_with: "",
        search: search,
        limit: perPage,
      })
    );
    dispatch(socialPlatform(null));
    if (currentPage > lastPage) {
      dispatch(setZero());
    }
  }, [dispatch, page, search, currentPage, lastPage, perPage]);
  const refresh = () => {
    dispatch(
      reqRegData({
        page: page,
        registered_with: "",
        search: search,
        limit: perPage,
      })
    );
  };
  return (
    <div>
      <Tables refresh={refresh} />
    </div>
  );
};

export default RegistredUsers;
