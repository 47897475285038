import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "reactstrap";
import {
  reqGetEntries,
  reqWinner,
  deleteWinner,
  entryId,
  reqEntryDelete,
  reqUserEntry,
  reqAllGetEntries,
} from "../../store/entries/actions";
import { reqContestDetails } from "../../store/contest/actions";
import { Row, Col, Spinner } from "reactstrap";
import { userId } from "../../store/data/actions";
import UnknownPerson from "../../assets/images/test.png";
import thumbImg from "../../assets/images/img-thumb.jpg";

const Participants = () => {
  const [toggle, setToggle] = useState(false);
  const { contestId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.Entries.participantsEntries?.data?.data
  );
  const success = useSelector(
    (state) => state.Entries.participantsEntries?.data
  );
  const contestDetails = useSelector(
    (state) => state.Contest.contestDetails?.data
  );

  const winnerUser = useSelector(
    (state) => state.Entries.contestWinner?.data?.success
  );

  const deleteEntry = useSelector(
    (state) => state.Entries.entryDelete?.success
  );

  // for all entries selector
  const allEntries = useSelector(
    (state) => state.Entries.allParticipantsEntries?.data?.data
  );

  useEffect(() => {
    dispatch(reqGetEntries({ contest_id: contestId }));
    dispatch(reqAllGetEntries({ contest_id: contestId }));
    dispatch(reqContestDetails(contestId));
    if (winnerUser === 1) {
      alert(`Winner is successfully Set!`);
      dispatch(reqGetEntries({ contest_id: contestId }));
      dispatch(deleteWinner());
    }
    if (deleteEntry === 1) {
      alert(`Perticipant entry successfully deleted!`);
      dispatch(reqGetEntries({ contest_id: contestId }));
      dispatch(deleteWinner());
    }
  }, [dispatch, contestId, winnerUser, deleteEntry]);

  const viewParticipants = (id) => {
    dispatch(userId(parseInt(id)));
    history.push(`/user-details/${id}`);
  };

  const viewEntry = (id) => {
    dispatch(entryId(id));
    history.push(`/entry-details/${id}`);
  };

  const setAsWinner = (entry) => {
    if (
      window.confirm(
        `Are you sure, you want to set ${entry.user.username} as winner for contest ${contestDetails.title}?`
      )
    ) {
      dispatch(reqWinner({ contest_id: entry.contest_id, entry_id: entry.id }));
    }
  };

  const deleteParticipant = (entry) => {
    if (
      window.confirm(
        `Are you sure, you want to delele entry submitted by ${entry?.user?.username}?`
      )
    ) {
      dispatch(reqEntryDelete(entry.id));
    }
  };

  const updateEntry = (id) => {
    dispatch(entryId(id));
    dispatch(reqUserEntry(id));
    history.push("/update-entry");
  };

  return (
    <div>
      {success?.success === 0 && (
        <>
          <Button className="m-2" onClick={() => history.goBack()}>
            Back
          </Button>
          <h2 className="m-2">{success?.message}</h2>
        </>
      )}
      {contestDetails?.id !== parseInt(contestId) && success?.success !== 0 && (
        <Spinner color="dark" />
      )}
      {(data?.length !== 0 || toggle) &&
        allEntries?.length !== 0 &&
        contestDetails?.id === parseInt(contestId) && (
          <div className="container-fluid">
            <Row className="align-items-center m-3">
              <Col>
                <Row className="align-items-center">
                  <Button onClick={() => history.goBack()}>Back</Button>
                  {!toggle && (
                    <h6 className="ml-4 mr-4">
                      Active Entries: {data?.length || "-"}
                    </h6>
                  )}
                  {toggle && (
                    <h6 className="ml-4 mr-4">
                      All Entries: {allEntries?.length || "-"}
                    </h6>
                  )}
                  <Button
                    color="primary"
                    onClick={() => history.push("/create-entry")}
                  >
                    Add Entry
                  </Button>
                  <Button
                    className="ml-4"
                    color="primary"
                    outline
                    onClick={() => setToggle(!toggle)}
                  >
                    {toggle ? "Active Entries" : "All Entries"}
                  </Button>
                  <Col>
                    <h6 className="text-right">
                      Contest Name :- {contestDetails?.title || "-"}
                    </h6>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">
                      {!toggle ? "Active Participants" : "All Participants"}
                    </h4>
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-striped"
                        >
                          <thead>
                            <tr>
                              <th>User Id</th>
                              <th>User</th>
                              {!toggle && <th>Total Votes</th>}
                              <th>Status</th>
                              {contestDetails?.type === "video" ? (
                                <th>Video</th>
                              ) : contestDetails?.type === "image" ? (
                                <th>Image</th>
                              ) : contestDetails?.type === "both" ? (
                                <th>Image / Video Thumb.</th>
                              ) : (
                                <th>Image</th>
                              )}

                              <th className="text-center">Actions</th>
                            </tr>
                          </thead>
                          {toggle && (
                            <tbody>
                              {allEntries?.map((entry) => (
                                <tr key={entry.id}>
                                  <th>{entry.u_id || "--"}</th>
                                  {entry.user ? (
                                    <th>
                                      <img
                                        className="mr-2"
                                        src={entry.user.image}
                                        onError={e => {e.target.src = UnknownPerson}}
                                        loading="lazy"
                                        alt="user"
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                      {entry.user.username || "--"}
                                    </th>
                                  ) : (
                                    <th>No user Found</th>
                                  )}
                                  <th>{entry.status || "--"}</th>
                                  <th>
                                    {contestDetails?.type === "image" ? (
                                      <img
                                        className="mr-2"
                                        src={entry.url}
                                        onError={e => {e.target.src = thumbImg}}
                                        loading="lazy"
                                        alt="user"
                                        style={{
                                          width: "100px",
                                          height: "auto",
                                          borderRadius: "10px",
                                        }}
                                      />
                                    ) : contestDetails?.type === "video" ? (
                                      entry.y_link ? (
                                        <iframe
                                          width="100px"
                                          height="auto"
                                          src={`https://www.youtube.com/embed/${entry?.y_link}`}
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                          allowFullScreen
                                          title={entry?.title}
                                          style={{
                                            borderRadius: "10px",
                                          }}
                                        />
                                      ) : (
                                        <video
                                          src={entry?.url}
                                          frameBorder="0"
                                          loop
                                          controls
                                          title={entry?.title}
                                          style={{
                                            width: "100px",
                                            height: "auto",
                                            borderRadius: "10px",
                                          }}
                                        />
                                      )
                                    ) : contestDetails?.type === "both" ? (
                                      <div>
                                        <img
                                          className="mr-2"
                                          src={entry.url}
                                          onError={e => {e.target.src = thumbImg}}
                                          loading="lazy"
                                          alt="user"
                                          style={{
                                            width: "100px",
                                            height: "auto",
                                            borderRadius: "10px",
                                          }}
                                        />
                                        <img
                                          className="mr-2"
                                          src={`https://img.youtube.com/vi/${entry.y_link}/mqdefault.jpg`}
                                          loading="lazy"
                                          alt="user"
                                          style={{
                                            width: "100px",
                                            height: "auto",
                                            borderRadius: "10px",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <img
                                        className="mr-2"
                                        src={thumbImg}
                                        loading="lazy"
                                        alt="user"
                                        style={{
                                          width: "100px",
                                          height: "auto",
                                          borderRadius: "10px",
                                        }}
                                      />
                                    )}
                                  </th>
                                  <th className="text-center">
                                    <Button
                                      outline
                                      color="primary"
                                      onClick={() =>
                                        viewParticipants(entry.u_id)
                                      }
                                    >
                                      <i className="ti-user"></i>
                                    </Button>
                                    <Button
                                      className="ml-2"
                                      outline
                                      color="primary"
                                      onClick={() => viewEntry(entry.id)}
                                    >
                                      Entry Details
                                    </Button>
                                    <Button
                                      outline
                                      color="info"
                                      className="mr-2 ml-2"
                                      onClick={() => updateEntry(entry.id)}
                                    >
                                      <i className="ti-pencil-alt"></i>
                                    </Button>
                                    <Button
                                      outline
                                      color="danger"
                                      onClick={() =>
                                        deleteParticipant({ ...entry })
                                      }
                                    >
                                      <i className="ti-trash"></i>
                                    </Button>
                                  </th>
                                </tr>
                              ))}
                            </tbody>
                          )}
                          {!toggle && (
                            <tbody>
                              {data?.map((entry) => (
                                <tr key={entry.id}>
                                  <th>{entry.u_id || "--"}</th>
                                  {entry.user ? (
                                    <th>
                                      <img
                                        className="mr-2"
                                        src={entry.user.image}
                                        onError={e => {e.target.src = UnknownPerson}}
                                        loading="lazy"
                                        alt="user"
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                      {entry.user.username || "--"}
                                    </th>
                                  ) : (
                                    <th>No user found</th>
                                  )}
                                  <th>{entry.totalvotes}</th>
                                  <th>{entry.status}</th>
                                  <th>
                                    {contestDetails?.type === "image" ? (
                                      <img
                                        className="mr-2"
                                        src={entry.url}
                                        onError={(e) => {
                                          e.target.src = thumbImg;
                                        }}
                                        loading="lazy"
                                        alt="user"
                                        style={{
                                          width: "100px",
                                          height: "auto",
                                          borderRadius: "10px",
                                        }}
                                      />
                                    ) : contestDetails?.type === "video" ? (
                                      entry.y_link ? (
                                        <iframe
                                          width="100px"
                                          height="auto"
                                          src={`https://www.youtube.com/embed/${entry?.y_link}`}
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                          allowFullScreen
                                          title={entry?.title}
                                          style={{
                                            borderRadius: "10px",
                                          }}
                                        />
                                      ) : (
                                        <video
                                          src={entry?.url}
                                          frameBorder="0"
                                          loop
                                          controls
                                          title={entry?.title}
                                          style={{
                                            width: "100px",
                                            height: "auto",
                                            borderRadius: "10px",
                                          }}
                                        />
                                      )
                                    ) : contestDetails?.type === "both" ? (
                                      <div>
                                        <img
                                          className="mr-2"
                                          src={entry.url}
                                          onError={e => {e.target.src = thumbImg}}
                                          loading="lazy"
                                          alt="user"
                                          style={{
                                            width: "100px",
                                            height: "auto",
                                            borderRadius: "10px",
                                          }}
                                        />
                                        <img
                                          className="mr-2"
                                          src={`https://img.youtube.com/vi/${entry.y_link}/mqdefault.jpg`}
                                          loading="lazy"
                                          alt="user"
                                          style={{
                                            width: "100px",
                                            height: "auto",
                                            borderRadius: "10px",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <img
                                        className="mr-2"
                                        src={thumbImg}
                                        loading="lazy"
                                        alt="user"
                                        style={{
                                          width: "100px",
                                          height: "auto",
                                          borderRadius: "10px",
                                        }}
                                      />
                                    )}
                                  </th>
                                  <th className="text-center">
                                    <Button
                                      outline
                                      color="primary"
                                      onClick={() =>
                                        viewParticipants(entry.u_id)
                                      }
                                    >
                                      <i className="ti-user"></i>
                                    </Button>
                                    <Button
                                      className="ml-2"
                                      outline
                                      color="primary"
                                      onClick={() => viewEntry(entry.id)}
                                    >
                                      Entry Details
                                    </Button>
                                    {!entry.winner && (
                                      <Button
                                        className="ml-2 mr-2"
                                        outline
                                        color="info"
                                        onClick={() =>
                                          setAsWinner({ ...entry })
                                        }
                                      >
                                        Set as Winner
                                      </Button>
                                    )}
                                    {entry.winner === "yes" && (
                                      <Button
                                        className="ml-2 mr-2"
                                        color="success"
                                        disabled
                                      >
                                        Event Winner
                                      </Button>
                                    )}
                                    <Button
                                      outline
                                      color="info"
                                      className="mr-2"
                                      onClick={() => updateEntry(entry.id)}
                                    >
                                      <i className="ti-pencil-alt"></i>
                                    </Button>
                                    <Button
                                      outline
                                      color="danger"
                                      onClick={() =>
                                        deleteParticipant({ ...entry })
                                      }
                                    >
                                      <i className="ti-trash"></i>
                                    </Button>
                                  </th>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      {data?.length === 0 &&
        !toggle &&
        contestDetails?.id === parseInt(contestId) && (
          <>
            <Button className="m-2" onClick={() => history.goBack()}>
              Back
            </Button>{" "}
            <Button
              color="primary"
              className="m-2"
              onClick={() => history.push("/create-entry")}
            >
              Add Entry
            </Button>
            <Button
              className="m-2"
              color="primary"
              outline
              onClick={() => setToggle(!toggle)}
            >
              {toggle ? "Active Entries" : "All Entries"}
            </Button>
            <h2 className="m-2">No Active Participants Entry Available</h2>
          </>
        )}
      {allEntries?.length === 0 &&
        toggle &&
        contestDetails?.id === parseInt(contestId) && (
          <>
            <Button className="m-2" onClick={() => history.goBack()}>
              Back
            </Button>{" "}
            <Button
              color="primary"
              className="m-2"
              onClick={() => history.push("/create-entry")}
            >
              Add Entry
            </Button>
            <Button
              className="m-2"
              color="primary"
              outline
              onClick={() => setToggle(!toggle)}
            >
              {toggle ? "Active Entries" : "All Entries"}
            </Button>
            <h2 className="m-2">No Participants Entry Available</h2>
          </>
        )}
    </div>
  );
};

export default Participants;
