export const REQ_CONTEST_LIST = "REQ_CONTEST_LIST"
export const CONTEST_LIST_SUCCESS = "CONTEST_LIST_SUCCESS"
export const CONTEST_LIST_ERROR = "CONTEST_LIST_ERROR"


export const CONTEST_ID = "CONTEST_ID"


export const REQ_CONTEST_DETAILS ="REQ_CONTEST_DETAILS"
export const CONTEST_DETAILS_SUCCESS = "CONTEST_DETAILS_SUCCESS"
export const CONTEST_DETAILS_ERROR = "CONTEST_DETAILS_ERROR"


export const REQ_DELETED_CONTEST = "REQ_DELETED_CONTEST"
export const DELETE_CONTEST_SUCCESS = "DELETE_CONTEST_SUCCESS"
export const DELETE_CONTEST_ERROR ="DELETE_CONTEST_ERROR"
export const CLEAR_DELETE_CONTEST ="CLEAR_DELETE_CONTEST"





export const REQ_CREATE_CONTEST = "REQ_CREATE_CONTEST"
export const CREATE_CONTEST_SUCCESS = "CREATE_CONTEST_SUCCESS"
export const CREATE_CONTEST_ERROR = "CREATE_CONTEST_ERROR"


export const REQ_UPDATE_CONTEST = 'REQ_UPDATE_CONTEST'
export const UPDATE_CONTEST_SUCCESS = 'UPDATE_CONTEST_SUCCESS'
export const UPDATE_CONTEST_ERROR = 'UPDATE_CONTEST_ERROR'

export const REQ_USER_CONTEST = 'REQ_USER_CONTEST'
export const USER_CONTEST_SUCCESS = 'USER_CONTEST_SUCCESS'
export const USER_CONTEST_ERROR = 'USER_CONTEST_ERROR'

export const REQ_APPROVE_CONTEST = 'REQ_APPROVE_CONTEST'
export const APPROVE_CONTEST_SUCCESS = 'APPROVE_CONTEST_SUCCESS'
export const APPROVE_CONTEST_ERROR = 'APPROVE_CONTEST_ERROR'

export const ADD_CONTEST_SEARCH = "ADD_CONTEST_SEARCH"