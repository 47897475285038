import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLatest,
} from "redux-saga/effects";

// Login Redux States
import { REQ_DASHBOARD_DATA, LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  dashboardData,
  dashboardDataError,
} from "./actions";

//AUTH related methods

import { loginUserss, logout, dashboardApi } from "../../../helpers/callApi";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(loginUserss, user.email, user.password);
    localStorage.setItem("loginInfo", JSON.stringify(response));
    yield put(loginSuccess(response));
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* dashboard() {
  try {
    const response = yield call(dashboardApi);
    yield put(dashboardData(response));
  } catch (error) {
    yield put(dashboardDataError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(logout);
    yield put(logoutUserSuccess(response));
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchDashboard() {
  yield takeLatest(REQ_DASHBOARD_DATA, dashboard);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchDashboard),
  ]);
}

export default authSaga;
