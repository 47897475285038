import React, { useEffect } from "react";
import EntryTable from "../Tables/EntryTable"

import { useDispatch, useSelector } from "react-redux";
import {reqSapEntries, deleteWinner} from "../../store/entries/actions"
import { setZero } from "../../store/data/actions";


const Entries = () => {
    const dispatch = useDispatch();
    const page = useSelector((state) => state.UserData.page);
    const perPage = useSelector((state) => state.UserData?.perPage);
    const search = useSelector((state) => state.Entries.entrySearch)
    const currentPage = useSelector(state => state.Entries.sapEntries?.data?.current_page)
    const lastPage =  useSelector(state => state.Entries.sapEntries?.data?.last_page)
    const deleteEntry = useSelector(
        (state) => state.Entries.entryDelete?.success
      );

    useEffect(() => {
        dispatch(reqSapEntries({ page: page, limit: perPage, search: search}));
        if (currentPage > lastPage) {
          dispatch(setZero());
        } 
        if(deleteEntry === 1) {
            alert('entry is successfully deleted!')
            dispatch(deleteWinner());
        }
      }, [dispatch, page, perPage, search, currentPage, lastPage, deleteEntry]);
    return (
        <div>
            <EntryTable/>
        </div>
    )
}

export default Entries
