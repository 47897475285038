import {
  REQ_CHANGE_PW,
  CHANGE_PW_SUCCESS,
  CHANGE_PW_ERROR,
  CLEAR_DELETE_PW,
} from "./actionTypes";

const initialState = {
  changePW: null,
  chnagePWErr: null,
};

const changePW = (state = initialState, action) => {
  switch (action.type) {
    case REQ_CHANGE_PW:
      state = { ...state };
      break;
    case CHANGE_PW_SUCCESS:
      state = { ...state, changePW: action.payload };
      break;
    case CHANGE_PW_ERROR:
      state = { ...state, chnagePWErr: action.payload };
      break;
    case CLEAR_DELETE_PW:
      state = { ...state, changePW: null, chnagePWErr: null };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default changePW;
