import React from "react";
import ReactEcharts from "echarts-for-react";
import {useSelector} from "react-redux"

const Doughnut = () => {
  const websiteUser = useSelector(state => state.Login?.dashboardData?.data?.website_user)
  const mobileUser = useSelector(state => state.Login?.dashboardData?.data?.mobile_user)
  const appUser = useSelector(state => state.Login?.dashboardData?.data?.app_user)
  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        x: "left",
        data: ["Website", "Mobile", "App"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#f8b425", "#38a4f8", "#3c4ccf"],
      series: [
        {
          name: "Total User Entries",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "20",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: [
            { value: websiteUser, name: "Website" },
            { value: mobileUser, name: "Mobile" },
            { value: appUser, name: "App" }
          ],
        },
      ],
    };
  };
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "100%" }} option={getOption()} />
    </React.Fragment>
  );
};
export default Doughnut;
