import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchUser } from "../../store/data/actions";
import { Row, Col, Spinner, Card, CardBody, Button } from "reactstrap";
import UnknownPerson from "../../assets/images/test.png";
import Thumb from "../../assets/images/img-thumb.jpg";

const UserDetails = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const userDetails = useSelector((state) => state.UserData.userDetails?.data);
  const userDetailsErr = useSelector((state) => state.UserData.userDetailsErr);
  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [dispatch, userId]);
  return (
    <div>
      {!parseInt(userId) && <h2>User Not Found</h2>}
      {parseInt(userId) &&
        userDetails?.id !== parseInt(userId) &&
        !userDetailsErr && <Spinner color="dark" />}
      {userDetailsErr && (
        <div>
          <Row className="m-2">
            <Button onClick={() => history.goBack()}>Back</Button>
          </Row>
          <h2 className="m-2">{userDetailsErr?.message}</h2>
        </div>
      )}
      {parseInt(userId) && userDetails?.id === parseInt(userId) && (
        <div className="container-fluid">
          <Row className="m-2">
            <Button onClick={() => history.goBack()}>Back</Button>
          </Row>
          <Row className="align-items-center col-12 justify-content-center">
            <img
              src={userDetails?.image}
              onError={(e) => {
                e.target.src = UnknownPerson;
              }}
              alt="user"
              className="m-4"
              style={{ borderRadius: "10px", width: "200px", height: "auto" }}
            />
          </Row>

          <Row className="align-items-center col-12 justify-content-center">
            {userDetails?.insta_url && (
              <a href={userDetails?.insta_url} target="_blank">
                <i
                  class="ti-instagram"
                  style={{
                    fontSize: "25px",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                ></i>
              </a>
            )}
            {userDetails?.fb_url && (
              <a href={userDetails?.fb_url} target="_blank">
                <i
                  class="ti-facebook"
                  style={{
                    fontSize: "25px",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                ></i>
              </a>
            )}
            {userDetails?.linkedin_url && (
              <a href={userDetails?.linkedin_url} target="_blank">
                <i
                  class="ti-linkedin"
                  style={{
                    fontSize: "25px",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                ></i>
              </a>
            )}
            {userDetails?.twitter_url && (
              <a href={userDetails?.twitter_url} target="_blank">
                <i
                  class="ti-twitter"
                  style={{
                    fontSize: "25px",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                ></i>
              </a>
            )}
            {userDetails?.website && (
              <a href={userDetails?.website} target="_blank">
                <i
                  class="ti-world"
                  style={{
                    fontSize: "25px",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                ></i>
              </a>
            )}
          </Row>

          <Row>
            <div className="col-12">
              <Card>
                <CardBody>
                  <h3 className="card-title mb-4">User Details</h3>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      User Id
                    </label>
                    <Col sm={10}>
                      <h5>{userDetails?.id || "-"}</h5>
                    </Col>
                  </Row>
                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Name
                    </label>
                    <Col sm={10}>
                      <h5>{userDetails?.username || "-"}</h5>
                    </Col>
                  </Row>
                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Phone
                    </label>
                    <Col sm={10}>
                      {userDetails?.phone ? (
                        <a href={`tel:${userDetails?.phone}`}>
                          <h5>{userDetails?.phone}</h5>
                        </a>
                      ) : (
                        <>--</>
                      )}
                    </Col>
                  </Row>
                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Country
                    </label>
                    <Col sm={10}>
                      <h5>{userDetails?.country || "-"}</h5>
                    </Col>
                  </Row>
                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      fb username
                    </label>
                    <Col sm={10}>
                      <h5>{userDetails?.fb_username || "-"}</h5>
                    </Col>
                  </Row>
                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Cover Image
                    </label>
                    <img
                      src={userDetails?.cover}
                      onError={(e) => {
                        e.target.src = Thumb;
                      }}
                      alt="cover_image"
                      className="m-4"
                      style={{
                        borderRadius: "5px",
                        width: "100px",
                        height: "auto",
                      }}
                    />
                  </Row>
                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Email
                    </label>
                    <Col sm={10}>
                      <h5>{userDetails?.email || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Type
                    </label>
                    <Col sm={10}>
                      <h5>{userDetails?.user_type || "-"}</h5>
                    </Col>
                  </Row>
                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      User Status
                    </label>
                    <Col sm={10}>
                      <h5>{userDetails?.user_status || "-"}</h5>
                    </Col>
                  </Row>
                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Subscribe
                    </label>
                    <Col sm={10}>
                      <h5>{userDetails?.subscribe || "-"}</h5>
                    </Col>
                  </Row>
                  {userDetails?.location && (
                    <Row className="form-group align-items-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Location
                      </label>
                      <Col sm={10}>
                        <h5>{userDetails?.location || "-"}</h5>
                      </Col>
                    </Row>
                  )}
                  {userDetails?.social_platform && (
                    <Row className="form-group align-items-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Social Platform
                      </label>
                      <Col sm={10}>
                        <h5>{userDetails?.social_platform || "-"}</h5>
                      </Col>
                    </Row>
                  )}
                  {userDetails?.gcm_regid && (
                    <Row className="form-group align-items-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        GCM Regid
                      </label>
                      <Col sm={10}>
                        <h5>{userDetails?.gcm_regid || "-"}</h5>
                      </Col>
                    </Row>
                  )}
                  {userDetails?.email_verification && (
                    <Row className="form-group align-items-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Email Verification
                      </label>
                      <Col sm={10}>
                        <h5>{userDetails?.email_verification || "-"}</h5>
                      </Col>
                    </Row>
                  )}
                  {userDetails?.created_at && (
                    <Row className="form-group align-items-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Created At
                      </label>
                      <Col sm={10}>
                        <h5>
                          {new Date(userDetails?.created_at).toLocaleString()}
                        </h5>
                      </Col>
                    </Row>
                  )}
                  {userDetails?.updated_at && (
                    <Row className="form-group align-items-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Updated At
                      </label>
                      <Col sm={10}>
                        <h5>
                          {new Date(userDetails?.updated_at).toLocaleString()}
                        </h5>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </div>
          </Row>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
