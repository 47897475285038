import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  incresePage,
  decresePage,
  perPageEntries,
  searchCondi,
} from "../../store/data/actions";
import { useHistory } from "react-router-dom";
import {
  contestId,
  reqDeleteContest,
  clearDeleteContest,
  addContestSearch,
} from "../../store/contest/actions";
import {
  Row,
  Col,
  Button,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";

const ContestTable = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const page = useSelector((state) => state.UserData.page);
  const searchToggle = useSelector((state) => state.UserData.searchCondi);
  const perPage = useSelector((state) => state.UserData?.perPage);
  const limit = useSelector((state) => state.Contest.contestList?.data?.limit);
  const search = useSelector((state) => state.Contest.addContestSearch);
  const currentPage = useSelector(
    (state) => state.Contest.contestList?.data?.current_page
  );
  const lastPage = useSelector(
    (state) => state.Contest.contestList?.data?.last_page
  );
  const contestList = useSelector((state) => state.Contest.contestList);
  const error = useSelector((state) => state.Contest.contestListError);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const viewContest = (id) => {
    dispatch(contestId(id));
    history.push(`/contest-details/${id}`);
  };

  const deleteEvent = async (contest) => {
    if (
      window.confirm(`Are you sure, you want to delele ${contest.title} event?`)
    ) {
      dispatch(reqDeleteContest(contest.id));
    }
  };

  const viewParticipants = (id) => {
    dispatch(contestId(id));
    history.push(`/participants/${id}`);
  };

  const updateContest = (id) => {
    dispatch(contestId(id));
    history.push("/update-contest");
  };

  const errorHandle = () => {
    alert(error?.message);
    dispatch(clearDeleteContest());
  };

  const onSubmit = (data) => {
    if (data.search !== search) {
      dispatch(searchCondi(true));
      dispatch(addContestSearch(data.search));
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <Row className="align-items-center m-3">
          <Col>
            <Row className="align-items-center">
              <Button
                disabled={page <= 1}
                onClick={() => dispatch(decresePage(1))}
              >
                Prev.
              </Button>
              <h6 className="ml-4 mr-4">{currentPage}</h6>
              <Button
                disabled={page >= lastPage}
                onClick={() => dispatch(incresePage(1))}
              >
                Next
              </Button>
              <h6 className="ml-4 mr-4">Total Pages: {lastPage}</h6>
            </Row>
          </Col>
          <Col>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret>Per Page - {perPage}</DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => dispatch(perPageEntries(5))}>
                  5
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(10))}>
                  10
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(20))}>
                  20
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(50))}>
                  50
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(100))}>
                  100
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(200))}>
                  200
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col>
            <form
              className="float-right d-md-flex"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input
                className="form-control"
                type="text"
                {...register("search")}
                defaultValue={search}
                placeholder="search"
              />
              <Button className="ml-2" color="info" outline type="submit">
                Search
              </Button>
            </form>
          </Col>
        </Row>
        {((!contestList && !error) ||
          currentPage !== page ||
          perPage !== limit ||
          searchToggle) && <Spinner color="dark" />}
        {error && errorHandle()}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Contest List</h4>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <table
                      id="tech-companies-1"
                      className="table table-striped"
                    >
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Title</th>
                          {/* <th>Image</th> */}
                          <th>Starting Date</th>
                          <th>Ending Date</th>
                          <th>Type</th>
                          <th>Contest Type</th>
                          <th>Status</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      {contestList && (
                        <tbody>
                          {currentPage === page &&
                            perPage === limit &&
                            !searchToggle &&
                            contestList?.data?.data.map((contest) => (
                              <tr key={contest.id}>
                                <th>{contest.id || "--"}</th>
                                <th>{contest.title || "--"}</th>
                                {/* <th><img src={contest.thumb_img } loading='lazy' alt="contest_thumb" style={{width: '100px', height: '100px'}} /></th> */}
                                <th>{contest.s_date || "--"}</th>
                                <th>{contest.e_date || "--"}</th>
                                <th>{contest.halla_type || "-"}</th>
                                <th>
                                  {(contest.halla_type === "contest" &&
                                    contest.type) ||
                                    "--"}
                                </th>
                                <th>{contest.status || "--"}</th>
                                <th
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  {/* <Button
                                    outline
                                    color="primary"
                                    onClick={() => viewContest(contest.id)}
                                  >
                                    Details
                                  </Button> */}

                                  <Button
                                    outline
                                    color="info"
                                    className="ml-2 mr-2"
                                    onClick={() => viewParticipants(contest.id)}
                                  >
                                    Participants
                                  </Button>

                                  <Button
                                    outline
                                    color="info"
                                    className="mr-2"
                                    onClick={() => updateContest(contest.id)}
                                  >
                                    <i className="ti-pencil-alt"></i> Details
                                  </Button>

                                  <Button
                                    outline
                                    color="danger"
                                    onClick={() => deleteEvent({ ...contest })}
                                  >
                                    <i className="ti-trash"></i>
                                  </Button>
                                </th>
                              </tr>
                            ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContestTable;
