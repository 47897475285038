import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import UnknownPerson from "../../assets/images/test.png";
import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import {
  reqUpdateContest,
  clearDeleteContest,
  reqContestDetails,
} from "../../store/contest/actions";
import { fetchUser } from "../../store/data/actions";

const UpdateContest = () => {
  const [toggle, setToggle] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const contestId = useSelector((state) => state.Contest.contestId);
  const contest = useSelector((state) => state.Contest.contestDetails?.data);
  const { register, handleSubmit, watch } = useForm();

  const privateCode = watch("private");
  // const eventPera = watch("halla_type");

  const condi = useSelector(
    (state) => state.Contest.updateContest?.data?.success
  );
  const message = useSelector(
    (state) => state.Contest.updateContest?.data?.message
  );

  const error = useSelector((state) => state.Contest.updateContestError);

  useEffect(() => {
    dispatch(reqContestDetails(contestId));
    if (condi === 1) {
      alert(message);
      dispatch(clearDeleteContest());
      history.goBack();
      setToggle(false);
    } else if (condi === 0) {
      alert(message);
      dispatch(clearDeleteContest());
      setToggle(false);
    } else if (error) {
      alert(error?.message);
      dispatch(clearDeleteContest());
      setToggle(false);
    }
  }, [condi, message, dispatch, history, setToggle, error, contestId]);

  const userDetails = useSelector((state) => state.UserData.userDetails?.data);
  const userDetailsErr = useSelector((state) => state.UserData.userDetailsErr);
  useEffect(() => {
    if (contest) {
      dispatch(fetchUser(contest.user_id));
    }
  }, [contest]);
  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("id", contest?.id);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("image", data.image[0]);
    formData.append("thumb_img", data.thumb_img[0]);
    formData.append("img_credit", data.img_credit);
    formData.append("img_credit_url", data.img_credit_url);
    formData.append("prize", data.prize);
    formData.append("halla_type", data.halla_type);
    formData.append("type", data.type);
    formData.append("no_winner", data.no_winner);
    formData.append("s_name", data.s_name);
    formData.append("sponsor_logo", data.sponsor_logo[0]);
    formData.append("sponsor_description", data.sponsor_description);
    formData.append("s_url", data.s_url);
    formData.append("s_date", data.s_date);
    formData.append("e_date", data.e_date);
    formData.append("summary", data.summary);
    formData.append("hint", data.hint);
    formData.append("private", data.private);
    formData.append("private_code", data.private_code);
    formData.append("lat", data.lat);
    formData.append("long", data.long);
    formData.append("radius", data.radius);
    formData.append("allow_sharing", data.allow_sharing);
    formData.append("tag", data.tag);
    formData.append("owner", data.owner);
    formData.append("voting", data.voting);
    formData.append("platform", data.platform);
    formData.append("status", data.status);
    dispatch(reqUpdateContest(formData));
    setToggle(true);
  };
  console.log({ contest });
  return (
    <div>
      {contestId !== contest?.id && <Spinner color="dark" />}
      {contest && contestId === contest?.id && (
        <div className="container-fluid mt-4">
          {userDetails && (
            <>
              <Row className="align-items-center col-12 justify-content-center">
                <img
                  src={userDetails?.image}
                  onError={(e) => {
                    e.target.src = UnknownPerson;
                  }}
                  alt="user"
                  className="m-4"
                  style={{
                    borderRadius: "10px",
                    width: "200px",
                    height: "auto",
                  }}
                />
              </Row>
              <Row>
                <div className="col-12">
                  <Card>
                    <CardBody>
                      <h3 className="card-title mb-4">User Details</h3>

                      <Row className="form-group align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          User Id
                        </label>
                        <Col sm={10}>
                          <h5>
                            {userDetails?.id ? (
                              <Link to={`/user-details/${userDetails?.id}`}>
                                {userDetails?.id}
                              </Link>
                            ) : (
                              <>--</>
                            )}
                          </h5>
                        </Col>
                      </Row>
                      <Row className="form-group align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Name
                        </label>
                        <Col sm={10}>
                          <h5>{userDetails?.username || "-"}</h5>
                        </Col>
                      </Row>

                      <Row className="form-group align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Email
                        </label>
                        <Col sm={10}>
                          <h5>{userDetails?.email || "-"}</h5>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </>
          )}
          <Row>
            <div className="col-12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Update Contest / Evemt</h4>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">Title</label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("title")}
                          type="text"
                          defaultValue={contest?.title}
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Description
                      </label>
                      <Col sm={10}>
                        <textarea
                          className="form-control"
                          {...register("description")}
                          type="text"
                          defaultValue={contest?.description}
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">Image</label>
                      <Col sm={10}>
                        <input type="file" {...register("image")} />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Thumb Image
                      </label>
                      <Col sm={10}>
                        <input type="file" {...register("thumb_img")} />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Image credit
                      </label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("img_credit")}
                          defaultValue={contest?.img_credit}
                          type="text"
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Image credit URL
                      </label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("img_credit_url")}
                          defaultValue={contest?.img_credit_url}
                          type="text"
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">Prize</label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("prize")}
                          defaultValue={contest?.prize}
                          type="text"
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">Type</label>
                      <Col sm={10}>
                        <select
                          className="form-control"
                          {...register("halla_type")}
                        >
                          <option
                            value="contest"
                            selected={contest?.halla_type === "contest"}
                          >
                            Contest
                          </option>
                          <option
                            value="event"
                            selected={contest?.halla_type === "event"}
                          >
                            Event
                          </option>
                        </select>
                      </Col>
                    </Row>

                    {contest?.halla_type === "event" && (
                      <Row className="form-group">
                        <label className="col-sm-2 col-form-label">
                          Private
                        </label>
                        <Col sm={10}>
                          <select
                            className="form-control"
                            name="private"
                            {...register("private")}
                          >
                            <option value="0" selected={contest?.private === 0}>
                              No
                            </option>
                            <option value="1" selected={contest?.private === 1}>
                              Yes
                            </option>
                          </select>
                        </Col>
                      </Row>
                    )}

                    {(contest?.private === 1 || privateCode === "1") && (
                      <Row className="form-group">
                        <label className="col-sm-2 col-form-label">
                          Private Code
                        </label>
                        <Col sm={10}>
                          <input
                            className="form-control"
                            {...register("private_code")}
                            defaultValue={contest?.private_code}
                            required
                            type="text"
                          />
                        </Col>
                      </Row>
                    )}

                    {contest?.halla_type === "event" && (
                      <Row className="form-group">
                        <label className="col-sm-2 col-form-label">
                          Coordinate
                        </label>
                        <Col sm={5}>
                          <input
                            className="form-control"
                            {...register("lat")}
                            required
                            type="text"
                            placeholder="Latitude"
                            defaultValue={contest?.lat}
                          />
                        </Col>
                        <Col sm={5}>
                          <input
                            className="form-control"
                            {...register("long")}
                            required
                            type="text"
                            placeholder="Longitude"
                            defaultValue={contest?.long}
                          />
                        </Col>
                      </Row>
                    )}

                    {contest?.halla_type === "event" && (
                      <Row className="form-group">
                        <label className="col-sm-2 col-form-label">Range</label>
                        <Col sm={10}>
                          <input
                            className="form-control"
                            {...register("radius")}
                            required
                            type="text"
                            defaultValue={contest?.radius}
                          />
                        </Col>
                      </Row>
                    )}

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Contest Type
                      </label>
                      <Col sm={10}>
                        <select className="form-control" {...register("type")}>
                          <option
                            value="image"
                            selected={contest?.type === "image"}
                          >
                            Image
                          </option>
                          <option
                            value="video"
                            selected={contest?.type === "video"}
                          >
                            Video
                          </option>
                          {/* <option value="both" selected={contest?.type === "both"}>Both</option> */}
                        </select>
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        No. of winner
                      </label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("no_winner")}
                          defaultValue={contest?.no_winner}
                          type="text"
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Sponsor Name
                      </label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("s_name")}
                          defaultValue={contest?.s_name}
                          type="text"
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Sponsor Logo
                      </label>
                      <Col sm={10}>
                        <input type="file" {...register("sponsor_logo")} />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Sponsor Description
                      </label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("sponsor_description")}
                          defaultValue={contest?.s_desc}
                          type="text"
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Sponsor URL
                      </label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("s_url")}
                          defaultValue={contest?.s_url}
                          type="text"
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Starting Date
                      </label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("s_date")}
                          type="date"
                          defaultValue={contest?.s_date}
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Ending Date
                      </label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("e_date")}
                          type="date"
                          defaultValue={contest?.e_date}
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">Summary</label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("summary")}
                          defaultValue={contest?.summary}
                          type="text"
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">Hint</label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("hint")}
                          defaultValue={contest?.hint}
                          type="text"
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Social Sharing
                      </label>
                      <Col sm={10}>
                        <select
                          className="form-control"
                          {...register("allow_sharing")}
                        >
                          <option
                            value="1"
                            selected={contest?.allow_sharing === 1}
                          >
                            Yes
                          </option>
                          <option
                            value="0"
                            selected={contest?.allow_sharing === 0}
                          >
                            No
                          </option>
                        </select>
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Sharing tag
                      </label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("tag")}
                          defaultValue={contest?.tag}
                          type="text"
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Event Owner (Halla id)
                      </label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("owner")}
                          defaultValue={contest?.owner}
                          type="text"
                        />
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">Voting</label>
                      <Col sm={10}>
                        <select
                          className="form-control"
                          {...register("voting")}
                        >
                          <option
                            value="normal"
                            selected={contest?.voting === "normal"}
                          >
                            Normal
                          </option>
                          <option
                            value="social"
                            selected={contest?.voting === "social"}
                          >
                            Social
                          </option>
                          <option
                            value="all"
                            selected={contest?.voting === "all"}
                          >
                            All
                          </option>
                        </select>
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Platform
                      </label>
                      <Col sm={10}>
                        <select
                          className="form-control"
                          {...register("platform")}
                        >
                          <option
                            value="website"
                            selected={contest?.platform === "website"}
                          >
                            Website
                          </option>
                          <option
                            value="app"
                            selected={contest?.platform === "app"}
                          >
                            App
                          </option>
                          <option
                            value="mobile"
                            selected={contest?.platform === "mobile"}
                          >
                            Mobile
                          </option>
                          <option
                            value="all"
                            selected={contest?.platform === "all"}
                          >
                            All
                          </option>
                        </select>
                      </Col>
                    </Row>

                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">Status</label>
                      <Col sm={10}>
                        <select
                          className="form-control"
                          {...register("status")}
                        >
                          <option
                            value="active"
                            selected={contest?.status === "active"}
                          >
                            Active
                          </option>
                          <option
                            value="passive"
                            selected={contest?.status === "passive"}
                          >
                            Passive
                          </option>
                          <option
                            value="upcoming"
                            selected={contest?.status === "upcoming"}
                          >
                            Upcoming
                          </option>
                        </select>
                      </Col>
                    </Row>

                    <Row>
                      <Button className="m-2" disabled={toggle} type="submit">
                        Update
                      </Button>
                      <Button className="m-2" onClick={() => history.goBack()}>
                        Cancel
                      </Button>
                      {(!contest || toggle) && <Spinner color="dark" />}
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </div>
      )}
    </div>
  );
};

export default UpdateContest;
