import axios from "./axios";

export const participantsEntriesApi = (contest_id) => {
    const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
    const access_token = sessionData?.data.token;
    return new Promise((resolve, reject) => {
        axios
          .post(
            "/admin/contests/getentries",
            contest_id,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
}

export const allParticipantsEntriesApi = (contest_id) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
      axios
        .post(
          "/admin/contests/get_all_entries",
          contest_id,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
}

export const winnerApi = (res) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
    const access_token = sessionData?.data.token;
    return new Promise((resolve, reject) => {
        axios
          .post(
            "/admin/contests/select_winner",
            res,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
}

export const userEntryApi = (id) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/entry/get/${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

export const deleteEntryApi = (id) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/entry/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

export const updateEntryApi = (res) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
    const access_token = sessionData?.data.token;
    return new Promise((resolve, reject) => {
        axios
          .post(
            "/admin/entry/update",
            res,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
}

export const createEntryApi = (res) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
    const access_token = sessionData?.data.token;

    return new Promise((resolve, reject) => {
        axios
          .post(
            "/admin/entry/create",
            res,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
}

export const sapEntriesApi = (res) => {
  const sessionData = JSON.parse(localStorage.getItem("loginInfo"));
  const access_token = sessionData?.data.token;

  return new Promise((resolve, reject) => {
      axios
        .post(
          "/admin/entry",
          res,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
}