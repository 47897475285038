import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reqContestList, clearDeleteContest } from "../../store/contest/actions";
import { setZero, searchCondi } from "../../store/data/actions";
import ContestTable from "../Tables/contestTable";

const ContestList = () => {
  const dispatch = useDispatch();
  const page = useSelector((state) => state.UserData.page);
  const perPage = useSelector((state) => state.UserData?.perPage);
  const search = useSelector((state) => state.Contest.addContestSearch);
  const currentPage = useSelector(
    (state) => state.Contest.contestList?.data?.current_page
  );
  const lastPage = useSelector(
    (state) => state.Contest.contestList?.data?.last_page
  );
  const deletedContest = useSelector(
    (state) => state.Contest.deleteContest?.success
  );
  useEffect(() => {
    dispatch(searchCondi(true))
    dispatch(reqContestList({ page: page, limit: perPage, search: search, status: ''}));
    if (currentPage > lastPage) {
      dispatch(setZero());
    } else if (deletedContest === 1) {
      alert("contest is deleted successfully");
      dispatch(clearDeleteContest())
    }
  }, [dispatch, page, perPage, currentPage, lastPage, search, deletedContest]);
  return (
    <div>
      <ContestTable />
    </div>
  );
};

export default ContestList;
