import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { submitInput } from "../../store/banner/actions";
import UnknownPerson from "../../assets/images/img-thumb.jpg";

import {
  Col,
  Row,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Spinner,
} from "reactstrap";

import {
  incresePage,
  decresePage,
  perPageEntries,
  setZero,
  searchCondi
} from "../../store/data/actions";

import {
  reqBanner,
  reqSelectBanner,
  deleteSelectedBanner,
} from "../../store/banner/actions";

const BannerPage = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const bannerData = useSelector((state) => state.Banner.bannerData?.data);
  const selectedBanner = useSelector(
    (state) => state.Banner.selectedBanner?.data?.success
  );
  const submitInpt = useSelector((state) => state.Banner?.submitInput);
  const page = useSelector((state) => state.UserData.page);
  const limit = useSelector((state) => state.Banner.bannerData?.data?.limit);
  const lastPage = useSelector(
    (state) => state.Banner.bannerData?.data?.last_page
  );
  const currentPage = useSelector(
    (state) => state.Banner.bannerData?.data?.current_page
  );
  const perPage = useSelector((state) => state.UserData.perPage);
  const error = useSelector((state) => state.Banner);
  const searchToggle = useSelector((state) => state.UserData.searchCondi);
  useEffect(() => {
    if (submitInpt) {
      dispatch(reqBanner({ page: page, limit: perPage, search: submitInpt }));
    }
    if (selectedBanner === 1) {
      alert("Banner is Successfully added!");
      dispatch(deleteSelectedBanner());
    }
    if (selectedBanner === 0) {
      alert("Something Went Wrong!");
      dispatch(deleteSelectedBanner());
    }
    if (currentPage > lastPage) {
      dispatch(setZero());
    }
  }, [
    dispatch,
    submitInpt,
    page,
    perPage,
    selectedBanner,
    currentPage,
    lastPage,
  ]);

  const setBanner = (banner) => {
    if (
      window.confirm(
        `Are you sure, you want to set ${banner.title} as a Banner?`
      )
    ) {
      dispatch(reqSelectBanner({ entry_id: banner.id }));
    }
  };

  const onSubmit = (data) => {
    if (data.search !== submitInpt) {
      dispatch(setZero());
      dispatch(searchCondi(true));
      dispatch(submitInput(data.search));
    }
  };

  const errorHandle = () => {
    alert("Can't able to send request Please try again after sometime!");
    dispatch(deleteSelectedBanner());
  };

  return (
    <div className="container-fluid">
      {(error?.bannerError || error?.selectBannerError) && errorHandle()}
      <Row className="align-items-center m-3">
        <Col>
          <Row className="align-items-center">
            <Button
              disabled={page <= 1}
              onClick={() => dispatch(decresePage(1))}
            >
              Prev.
            </Button>
            <h6 className="ml-4 mr-4">{currentPage || "-"}</h6>
            <Button
              disabled={page >= lastPage}
              onClick={() => dispatch(incresePage(1))}
            >
              Next
            </Button>
            <h6 className="ml-4 mr-4">Total Pages: {lastPage || "-"}</h6>
          </Row>
        </Col>
        <Col>
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>Per Page - {perPage}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => dispatch(perPageEntries(5))}>
                5
              </DropdownItem>
              <DropdownItem onClick={() => dispatch(perPageEntries(10))}>
                10
              </DropdownItem>
              <DropdownItem onClick={() => dispatch(perPageEntries(20))}>
                20
              </DropdownItem>
              <DropdownItem onClick={() => dispatch(perPageEntries(50))}>
                50
              </DropdownItem>
              <DropdownItem onClick={() => dispatch(perPageEntries(100))}>
                100
              </DropdownItem>
              <DropdownItem onClick={() => dispatch(perPageEntries(200))}>
                200
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>
        <Col>
          <form
            className="float-right d-md-flex"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              className="form-control"
              type="text"
              {...register("search", { required: true })}
              placeholder="search"
              defaultValue={submitInpt}
              required
            />
            <Button className="ml-2" color="info" outline type="submit">
              Search
            </Button>
          </form>
        </Col>
      </Row>
      {submitInpt &&
        (!bannerData || currentPage !== page || perPage !== limit || searchToggle) && (
          <Spinner color="dark" />
        )}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Banner</h4>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <table id="tech-companies-1" className="table table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>User</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {!error?.selectBannerError && !error?.bannerError && (
                      <tbody>
                        {(currentPage === page &&
                          perPage === limit && !searchToggle) &&
                          bannerData?.data.map((banner) => (
                            <tr key={banner.id}>
                              <th>{banner.id}</th>
                              <th>
                                {banner?.contest?.type === "image" ? (
                                  <img
                                    className="mr-2"
                                    src={banner.url}
                                    loading="lazy"
                                    alt="banner"
                                    style={{
                                      width: "150px",
                                      height: "auto",
                                      borderRadius: "10px",
                                    }}
                                  />
                                ) : banner?.contest?.type === "video" ? (
                                  <img
                                    className="mr-2"
                                    src={`https://img.youtube.com/vi/${banner.y_link}/mqdefault.jpg`}
                                    loading="lazy"
                                    alt="banner"
                                    style={{
                                      width: "150px",
                                      height: "auto",
                                      borderRadius: "10px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="mr-2"
                                    src={UnknownPerson}
                                    loading="lazy"
                                    alt="banner"
                                    style={{
                                      width: "150px",
                                      height: "auto",
                                      borderRadius: "10px",
                                    }}
                                  />
                                )}
                              </th>
                              <th>{banner.title}</th>
                              <th>{`${banner.user.id} - ${banner.user.username}`}</th>
                              <th>{banner.video_uploaded}</th>
                              <th>
                                <Button
                                  outline
                                  color="success"
                                  onClick={() => setBanner({ ...banner })}
                                >
                                  Add banner
                                </Button>
                              </th>
                            </tr>
                          ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerPage;
