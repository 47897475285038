import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import ChangePassword from "./auth/changePassword/reducer"

//userData
import UserData from "./data/reducer"

// contest
import Contest from "./contest/reducer"

// Entries
import Entries from "./entries/reducer"

import Banner from "./banner/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ChangePassword,
  UserData,
  Contest,
  Entries,
  Banner
});

export default rootReducer;
