export const SUBMIT_INPUT = "SUBMIT_INPUT"
export const REQ_BANNER = "REQ_BANNER"
export const BANNER_SUCCESS = "BANNER_SUCCESS"
export const BANNER_FAILURE = "BANNER_FAILURE"
export const REQ_SELECT_BANNER = 'REQ_SELECT_BANNER' 
export const SELECT_BANNER_SUCCESS = "SELECT_BANNER_SUCCESS"
export const SELECT_BANNER_FAILED ="SELECT_BANNER_FAILED"
export const DELETE_SELECTED_BANNER ="DELETE_SELECTED_BANNER"

export const REQ_BANNER_LIST = 'REQ_BANNER_LIST'
export const BANNER_LIST_SUCCESS = 'BANNER_LIST_SUCCESS'
export const BANNER_LIST_ERROR = 'BANNER_LIST_ERROR'

export const REQ_BANNER_DELETE = 'REQ_BANNER_DELETE'
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS'
export const DELETE_BANNER_ERROR = 'DELETE_BANNER_ERROR'

export const REQ_BANNER_TEXT = "REQ_BANNER_TEXT"
export const BANNER_TEXT_SUCCESS = "BANNER_TEXT_SUCCESS"
export const BANNER_TEXT_ERROR = "BANNER_TEXT_ERROR"