import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  REQ_CONTEST_LIST,
  REQ_CONTEST_DETAILS,
  REQ_DELETED_CONTEST,
  REQ_CREATE_CONTEST,
  REQ_UPDATE_CONTEST,
  REQ_USER_CONTEST,
  REQ_APPROVE_CONTEST,
} from "./actionTypes";
import { searchCondi } from "../data/actions";
import {
  contestListSuccess,
  contestListError,
  contestDetailsSuccess,
  contestDetailsError,
  deleteContestSuccess,
  deleteContestError,
  createContestSuccess,
  createContestError,
  updateContestSuccess,
  updateContestError,
  userContestSuccess,
  userContestError,
  approveContestSuccess,
  approveContestError,
} from "./actions";
import {
  contestListApi,
  contestDetailsApi,
  deleteContestApi,
  createContestApi,
  updateContestApi,
  userContestApi,
  approveContestApi,
} from "../../helpers/callContestApi";

function* fetchContestList({ payload: { res } }) {
  try {
    const response = yield call(contestListApi, res);
    yield put(contestListSuccess(response));
    yield put(searchCondi(false));
  } catch (err) {
    yield put(contestListError(err));
  }
}

function* fetchContestDetails({ payload }) {
  try {
    const response = yield call(contestDetailsApi, payload.res);
    yield put(contestDetailsSuccess(response));
  } catch (err) {
    yield put(contestDetailsError(err));
  }
}

function* deleteContest({ payload }) {
  try {
    const response = yield call(deleteContestApi, payload.res);
    yield put(deleteContestSuccess(response));
  } catch (err) {
    yield put(deleteContestError(err));
  }
}

function* createContest({ payload: res }) {
  try {
    const response = yield call(createContestApi, res);
    yield put(createContestSuccess(response));
  } catch (err) {
    yield put(createContestError(err));
  }
}

function* updateContest({ payload: res }) {
  try {
    const response = yield call(updateContestApi, res);
    yield put(updateContestSuccess(response));
  } catch (err) {
    yield put(updateContestError(err));
  }
}

function* userContest({ payload: res }) {
  try {
    const response = yield call(userContestApi, res);
    yield put(userContestSuccess(response));
  } catch (err) {
    yield put(userContestError(err));
  }
}

function* approveContest({ payload: res, history }) {
  try {
    const response = yield call(approveContestApi, res);
    yield put(approveContestSuccess(response));
    console.log(response)
    if (response?.data?.success === 1) {
      history.goBack();
    }
  } catch (err) {
    yield put(approveContestError(err));
  }
}

export function* watchFetchContestList() {
  yield takeEvery(REQ_CONTEST_LIST, fetchContestList);
}

export function* watchFetchContestDetails() {
  yield takeEvery(REQ_CONTEST_DETAILS, fetchContestDetails);
}

export function* watchDeleteContest() {
  yield takeEvery(REQ_DELETED_CONTEST, deleteContest);
}

export function* watchCreateContest() {
  yield takeEvery(REQ_CREATE_CONTEST, createContest);
}

export function* watchUpdateContest() {
  yield takeEvery(REQ_UPDATE_CONTEST, updateContest);
}

export function* watchUserContest() {
  yield takeEvery(REQ_USER_CONTEST, userContest);
}

export function* watchApproveContest() {
  yield takeEvery(REQ_APPROVE_CONTEST, approveContest);
}

function* contestSaga() {
  yield all([
    fork(watchFetchContestList),
    fork(watchFetchContestDetails),
    fork(watchDeleteContest),
    fork(watchCreateContest),
    fork(watchUpdateContest),
    fork(watchUserContest),
    fork(watchApproveContest),
  ]);
}

export default contestSaga;
