import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import {
  incresePage,
  decresePage,
  addSearch,
  userId,
  perPageEntries,
  clearError,
  searchCondi,
  userStatus,
} from "../../store/data/actions";

const Tables = ({ refresh }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const page = useSelector((state) => state.UserData.page);
  const registredData = useSelector((state) => state.UserData.registeredUser);
  const error = useSelector((state) => state.UserData.error);
  const search = useSelector((state) => state.UserData.search);
  const lastPage = useSelector(
    (state) => state.UserData.registeredUser?.data?.last_page
  );
  const limit = useSelector(
    (state) => state.UserData.registeredUser?.data?.limit
  );
  const currentPage = useSelector(
    (state) => state.UserData.registeredUser?.data?.current_page
  );
  const userDatas = useSelector(
    (state) => state.UserData.registeredUser?.data?.data
  );
  // const socialFetch = useSelector(state => state.UserData.registeredUser?.data?.social_platform)
  // const socialLocal = useSelector(state => state.UserData.social)

  const searchToggle = useSelector((state) => state.UserData.searchCondi);
  const perPage = useSelector((state) => state.UserData.perPage);

  const { register, handleSubmit } = useForm();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const UserDetails = async (id) => {
    dispatch(userId(id));
    history.push(`/user-details/${id}`);
  };

  const handleActive = (id) => {
    dispatch(userStatus(id));
    refresh && refresh();
  };

  const errorHandle = () => {
    alert(error?.message);
    dispatch(clearError());
  };

  const onSubmit = (data) => {
    if (data.search !== search) {
      dispatch(searchCondi(true));
      dispatch(addSearch(data.search));
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <Row className="align-items-center m-3">
          <Col>
            <Row className="align-items-center">
              <Button
                disabled={page <= 1}
                onClick={() => dispatch(decresePage(1))}
              >
                Prev.
              </Button>
              <h6 className="ml-4 mr-4">{currentPage}</h6>
              <Button
                disabled={page >= lastPage}
                onClick={() => dispatch(incresePage(1))}
              >
                Next
              </Button>
              <h6 className="ml-4 mr-4">Total Pages: {lastPage}</h6>
            </Row>
          </Col>
          <Col>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret>Per Page - {perPage}</DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => dispatch(perPageEntries(5))}>
                  5
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(10))}>
                  10
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(20))}>
                  20
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(50))}>
                  50
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(100))}>
                  100
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(perPageEntries(200))}>
                  200
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col>
            <form
              className="float-right d-md-flex"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input
                className="form-control"
                type="text"
                {...register("search")}
                defaultValue={search}
                placeholder="search"
              />
              <Button className="ml-2" color="info" outline type="submit">
                Search
              </Button>
            </form>
          </Col>
        </Row>
        {((!registredData && !error) ||
          currentPage !== page ||
          perPage !== limit ||
          searchToggle) && <Spinner color="dark" />}
        {error && errorHandle()}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Users List</h4>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <table
                      id="tech-companies-1"
                      className="table table-striped"
                    >
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>User Name</th>
                          <th>Email</th>
                          <th>Social Platform</th>
                          <th>Created At</th>
                          <th>Updated At</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      {registredData && !error && (
                        <tbody>
                          {currentPage === page &&
                            perPage === limit &&
                            !searchToggle &&
                            userDatas?.map((userData) => (
                              <tr key={userData.id}>
                                <th>{userData.id || "--"}</th>
                                <th>{userData.username || "--"}</th>
                                <th>{userData.email || "--"}</th>
                                <th>{userData.social_platform || "--"}</th>
                                <th>
                                  {userData.created_at?.slice(0, 10) || "--"}
                                </th>
                                <th>
                                  {userData.updated_at?.slice(0, 10) || "--"}
                                </th>
                                <th>
                                  <Button
                                    color={
                                      userData.user_status === "yes" ||
                                      userData.user_status === "active"
                                        ? "success"
                                        : "danger"
                                    }
                                    outline
                                    onClick={() => handleActive(userData.id)}
                                  >
                                    {userData.user_status === "yes" ||
                                    userData.user_status === "active"
                                      ? "Active"
                                      : "Deactive"}
                                  </Button>
                                </th>
                                <th>
                                  <Button
                                    color="primary"
                                    outline
                                    onClick={() => UserDetails(userData.id)}
                                  >
                                    <i className="ti-user"></i>
                                  </Button>
                                </th>
                              </tr>
                            ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Tables;
