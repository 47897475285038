import React from "react";
import { Redirect } from "react-router-dom";

// Pages Component

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ChangePw from "../pages/Authentication/Changepw"

import Dashboard from "../pages/Dashboard";

// users
import RegisterdUsers from "../pages/users/registredUsers";
import FacebookUsers from "../pages/users/facebookUsers";
import GoogleUsers from "../pages/users/googleUsers";
import EmailUsers from "../pages/users/emailUsers";
import UserDetails from "../pages/Details/UserDetails";
import UnsubscribeUsers from "../pages/users/unsubscribeUsers";

// contest
import ContestList from "../pages/Contest/contestList"
import ActiveContest from "../pages/Contest/ActiveContest"
import ContestDetails from "../pages/Details/contestDetails"
import Participants from "../pages/Tables/participantsTable"
import UserContest from "../pages/Contest/userContest"

// Banner
import Banner from "../pages/banner"
import BannerList from "../pages/banner/bannerList"
import BannerForm from "../pages/banner/bannerText"

// Entry
import EntryDetails from "../pages/Details/EntryDetails"

// Forms
import UpdateContest from "../pages/Forms/updateContest"
import UpdateEntry from "../pages/Forms/updateEntry"
import CreateEntry from "../pages/Forms/createEntry"
import CreateContest from "../pages/Forms/createContest"
import ApprovalForm from "../pages/Forms/approvalForm"


// Charts
import UserPointWise from "../pages/users/userPointWise";

// logs
import Logs from "../pages/Tables/Logs"
import Entries from "../pages/Entries";


const authProtectedRoutes = [

  // users
  {path: "/registred-users", component: RegisterdUsers},
  {path: "/facebook-users", component: FacebookUsers},
  {path: "/google-users", component: GoogleUsers},
  {path: "/email-users", component: EmailUsers},
  {path: "/user-details/:userId", component: UserDetails},
  {path: "/user-point-wise", component: UserPointWise},
  {path: '/unsubscribe-users', component: UnsubscribeUsers},

  // Contest
  {path:'/contest-list', component: ContestList},
  {path: '/active-contest', component: ActiveContest},
  {path:'/create-contest', component: CreateContest},
  {path: '/contest-details/:contestId', component: ContestDetails},
  {path: '/participants/:contestId', component:Participants },
  {path: '/contest-by-user', component: UserContest},

  // Banner
  {path: '/banner', component: Banner},
  {path: '/banner-list', component: BannerList},
  {path: '/banner-form', component: BannerForm},

  // Entry
  {path: '/entry-details/:entryId', component: EntryDetails},
  {path: '/entries', component: Entries},

  // Forms
  {path:'/update-contest', component: UpdateContest},
  {path: '/update-entry', component: UpdateEntry},
  {path: '/create-entry', component: CreateEntry},
  {path: '/approval-form', component: ApprovalForm},

  //Tables
  { path: '/change-password', component: ChangePw },
  { path: "/dashboard", component: Dashboard },

  // Logs
  {path: '/logs', component: Logs},

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
];

export { authProtectedRoutes, publicRoutes };
