import {
  REQ_GET_ENTRIES,
  GET_ENTRIES_SUCCESS,
  GET_ENTRIES_ERROR,

  REQ_ALL_GET_ENTRIES,
  GET_ALL_ENTRIES_SUCCESS,
  GET_ALL_ENTRIES_ERROR,

  REQ_WINNER,
  WINNER_SUCCESS,
  WINNER_ERROR,
  DELETE_WINNER,
  REQ_USER_ENTRY,
  USER_ENTRY_SUCCESS,
  USER_ENTRY_ERROR,
  ENTRY_ID,
  REQ_ENTRY_DELETE,
  DELETE_ENTRY_SUCCESS,
  DELETE_ENTRY_ERROR,
  REQ_UPDATE_ENTRY,
  UPDATE_ENTRY_SUCCESS,
  UPDATE_ENTRY_ERROR,

  REQ_CREATE_ENTRY,
  CREATE_ENTRY_SUCCESS,
  CREATE_ENTRY_FAILURE,
  ADD_ENTRY_SEARCH,
  REQ_SAP_ENTRIES,
  SAP_ENTRIES_SUCCESS,
  SAP_ENTRIES_ERR
} from "./actionTypes";

export const reqGetEntries = (res) => {
  return {
    type: REQ_GET_ENTRIES,
    payload: { res },
  };
};

export const getEntriesSuccess = (res) => {
  return {
    type: GET_ENTRIES_SUCCESS,
    payload: res,
  };
};

export const getEntriesError = (res) => {
  return {
    type: GET_ENTRIES_ERROR,
    payload: res,
  };
};

export const reqAllGetEntries = (res) => {
  return {
    type: REQ_ALL_GET_ENTRIES,
    payload: { res },
  };
};

export const getAllEntriesSuccess = (res) => {
  return {
    type: GET_ALL_ENTRIES_SUCCESS,
    payload: res,
  };
};

export const getAllEntriesError = (res) => {
  return {
    type: GET_ALL_ENTRIES_ERROR,
    payload: res,
  };
};

export const reqWinner = (res) => {
  return {
    type: REQ_WINNER,
    payload: { res },
  };
};

export const winnerSuccess = (res) => {
  return {
    type: WINNER_SUCCESS,
    payload: res,
  };
};

export const winnerError = (res) => {
  return {
    type: WINNER_ERROR,
    payload: res,
  };
};


export const deleteWinner = () => {
  return {
    type: DELETE_WINNER
  }
}

export const reqUserEntry = (res) => {
  return {
    type: REQ_USER_ENTRY,
    payload: res
  }
}

export const userEntrySuccess = (res) => {
  return {
    type: USER_ENTRY_SUCCESS,
    payload: res 
  }
}

export const userEntryError = (res) => {
  return {
    type: USER_ENTRY_ERROR,
    payload: res
  }
}

export const entryId = (res) => {
  return {
    type: ENTRY_ID,
    payload: res
  }
}

export const reqEntryDelete = (res) => {
  return {
    type: REQ_ENTRY_DELETE,
    payload: res
  }
}

export const deleteEntrySuccess = (res) => {
  return {
    type: DELETE_ENTRY_SUCCESS,
    payload: res 
  }
}

export const deleteEntryError = (res) => {
  return {
    type: DELETE_ENTRY_ERROR,
    payload: res
  }
}

export const reqUpdateEntry = (res) => {
  return {
    type: REQ_UPDATE_ENTRY,
    payload: res
  }
}

export const updateEntrySuccess = (res) => {
  return {
    type: UPDATE_ENTRY_SUCCESS,
    payload: res 
  }
}

export const updateEntryError = (res) => {
  return {
    type: UPDATE_ENTRY_ERROR,
    payload: res
  }
}


export const reqCreateEntry = (res) => {
  return {
    type: REQ_CREATE_ENTRY,
    payload: res
  }
}

export const createEntrySuccess = (res) => {
  return {
    type: CREATE_ENTRY_SUCCESS,
    payload: res
  }
}

export const createEntryFailure = (res) => {
  return {
    type: CREATE_ENTRY_FAILURE,
    payload: res
  }
}

export const addEntrySearch = (res) => {
  return {
    type: ADD_ENTRY_SEARCH,
    payload: res,
  };
};


export const reqSapEntries = (res) => {
  return {
    type: REQ_SAP_ENTRIES,
    payload: { res }
  }
}

export const sapEntriesSuccess = (res) => {
  return {
    type: SAP_ENTRIES_SUCCESS,
    payload: res 
  }
}

export const sapEntriesErr = (res) => {
  return {
    type: SAP_ENTRIES_ERR,
    payload: res
  }
}