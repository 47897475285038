import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  reqUserEntry,
  reqUpdateEntry,
  deleteWinner,
} from "../../store/entries/actions";
import { useForm } from "react-hook-form";

const UpdateEntry = () => {
  const [toggle, setToggle] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const entryDetails = useSelector((state) => state.Entries.userEntry?.data);
  const entryId = useSelector((state) => state.Entries.entryId);
  const updateEntry = useSelector((state) => state.Entries.updateEntry);
  const type = useSelector((state) => state.Contest.contestDetails?.data?.type);
  const contestId = useSelector((state) => state.Contest.contestId);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    let newLink = '';
    if (data?.y_link != null && data?.y_link?.length > 12) {
      let videoId = data?.y_link?.match(/^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/);
      newLink = videoId[2];
    } else if (data?.y_link?.length === 11) {
      newLink = data?.y_link;
    } 

    let formData = new FormData();
    formData.append("id", data.id);
    formData.append("contest_id", data.contest_id);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("y_link", newLink)
    formData.append("status", data.status);
    dispatch(reqUpdateEntry(formData));
    setToggle(true);
  };

  useEffect(() => {
    dispatch(reqUserEntry(entryId));
    if (updateEntry?.data?.success === 1) {
      alert(updateEntry?.data?.message);
      dispatch(deleteWinner());
      history.goBack();
      setToggle(false);
    } else if (updateEntry?.data?.success === 0) {
      alert(updateEntry?.data?.message);
      dispatch(deleteWinner());
      history.goBack();
      setToggle(false);
    }
  }, [
    dispatch,
    entryId,
    history,
    updateEntry?.data?.success,
    updateEntry?.data?.message,
    contestId,
  ]);

  return (
    <div>
      {!entryId && <h2>Entry Not Found</h2>}
      {((entryId && entryDetails?.id !== entryId)) && (
        <Spinner color="dark" />
      )}
      {entryId && entryDetails?.id === entryId && (
        <div className="container-fluid mt-4">
          <Row>
            <div className="col-12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Update Entry</h4>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">Id</label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("id", { required: true })}
                          value={entryDetails?.id}
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">User Id</label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          value={entryDetails?.u_id}
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Contest Id
                      </label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("contest_id", { required: true })}
                          type="number"
                          value={entryDetails?.contest_id}
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">Title</label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          {...register("title", { required: true })}
                          type="text"
                          defaultValue={entryDetails?.title}
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Description
                      </label>
                      <Col sm={10}>
                        <textarea
                          className="form-control"
                          {...register("description", { required: true })}
                          type="text"
                          defaultValue={entryDetails?.description}
                          required
                        />
                      </Col>
                    </Row>
                    {(type === "video" || type === "both") && (
                      <Row className="form-group">
                        <label className="col-sm-2 col-form-label">
                          Youtube Link
                        </label>
                        <Col sm={10}>
                          <input
                            className="form-control"
                            {...register("y_link")}
                            type="text"
                            defaultValue={entryDetails?.y_link}
                          />
                        </Col>
                      </Row>
                    )}
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Tweet Hashtag
                      </label>
                      <Col sm={10}>
                        <input
                          className="form-control"
                          type="text"
                          {...register("tweet")}
                          defaultValue={entryDetails?.tweet_hashtag}
                        />
                      </Col>
                    </Row>
                    <Row className="form-group">
                      <label className="col-sm-2 col-form-label">Status</label>
                      <Col sm={10}>
                        <select
                          className="form-control"
                          {...register("status")}
                        >
                          <option
                            value="active"
                            selected={entryDetails?.status === "active"}
                          >
                            active
                          </option>
                          <option
                            value="passive"
                            selected={entryDetails?.status === "passive"}
                          >
                            passive
                          </option>
                        </select>
                      </Col>
                    </Row>
                    <Row>
                      <Button type="submit" disabled={toggle} className="m-2">
                        Save
                      </Button>
                      <Button className="m-2" onClick={() => history.goBack()}>
                        Cancel
                      </Button>
                      {toggle && <Spinner color="dark"/>}
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </div>
      )}
    </div>
  );
};

export default UpdateEntry;
