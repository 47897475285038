import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Row, Col, CardBody, Card, Button } from "reactstrap";
import { reqContestDetails } from "../../store/contest/actions";

const ContestDetails = () => {
  const { contestId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const contestDetails = useSelector(
    (state) => state.Contest.contestDetails?.data
  );
  const successFailed = useSelector(
    (state) => state.Contest.contestDetails?.success
  );
  useEffect(() => {
    dispatch(reqContestDetails(contestId));
  }, [dispatch, contestId]);

  const backToContestList = () => {
    history.goBack();
  };
  return (
    <div>
      {!parseInt(contestId) || successFailed === 0 ? (
        <h2>Contest / Event Not Found</h2>
      ) : contestDetails?.id !== parseInt(contestId) ? (
        <Spinner color="dark" />
      ) : (
        <div className="container-fluid">
          <Row className="m-2">
            <Button onClick={backToContestList}>Back</Button>
          </Row>
          <Row className="align-items-center">
            <Row className="col-12 justify-content-center">
              <img
                src={contestDetails?.image}
                alt="contest_img"
                className="m-4"
                style={{ borderRadius: "10px", width: "200px", height: "auto" }}
              />
            </Row>

            <Col sm="6">
              <div className="float-right d-none d-md-block"></div>
            </Col>
          </Row>

          <Row>
            <div className="col-12">
              <Card>
                <CardBody>
                  <h3 className="card-title mb-4">{contestDetails?.halla_type === "event" ? "Event Details" : "Contest Details"}</h3>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      {contestDetails?.halla_type === "event" ? "Event Id" : "Contest Id"}
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.id || "-"}</h5>
                    </Col>
                  </Row>

                  {contestDetails?.user_id !== 0 && (
                    <Row className="form-group align-items-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        User Details
                      </label>
                      <Col className="form-group justify-content-center">
                        <Button
                          color="info"
                          outline
                          onClick={() =>
                            history.push(
                              `/user-details/${contestDetails?.user_id}`
                            )
                          }
                        >
                          {contestDetails?.user_id}
                        </Button>
                      </Col>
                    </Row>
                  )}

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Title
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.title || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Description
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.description || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Starting Date
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.s_date || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Ending Date
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.e_date || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Status
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.status || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Approval status
                    </label>
                    <Col sm={10}>
                      <h5>
                        {contestDetails?.is_user_contest_approve === 1
                          ? "pending"
                          : contestDetails?.is_user_contest_approve === 0
                          ? "Approved"
                          : "Declined"}
                      </h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Thumb Image
                    </label>
                    <Col sm={10}>
                      <img
                        src={contestDetails?.thumb_img}
                        alt="thumb_img"
                        style={{
                          borderRadius: "10px",
                          width: "100px",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Prize
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.prize || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Summary
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.summary || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Hint
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.hint || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Type
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.halla_type || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Contest Type
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.type || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Image Credit
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.img_credit || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Image Credit URL
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.img_credit_url || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      No. of Winner
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.no_winner || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Slug
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.slug || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Owner Id
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.owner || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Sponsor Logo
                    </label>
                    <Col sm={10}>
                      <img
                        src={contestDetails?.s_logo}
                        alt="sponsor_logo"
                        style={{
                          borderRadius: "10px",
                          width: "50px",
                          height: "50px",
                          objectFit: "contain",
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Sponsor Name
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.s_name || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Sponsor URL
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.s_url || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Sponsor description
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.s_desc || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Private
                    </label>
                    <Col sm={10}>
                      <h5>
                        {contestDetails?.private === 0
                          ? "No"
                          : contestDetails?.private === 1
                          ? "Yes"
                          : "-"}
                      </h5>
                    </Col>
                  </Row>

                  {contestDetails?.halla_type === "event" && (
                    <Row className="form-group align-items-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Langitude
                      </label>
                      <Col sm={10}>
                        <h5>{contestDetails?.lat || "-"}</h5>
                      </Col>
                    </Row>
                  )}

                  {contestDetails?.halla_type === "event" && (
                    <Row className="form-group align-items-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Longitude
                      </label>
                      <Col sm={10}>
                        <h5>{contestDetails?.long || "-"}</h5>
                      </Col>
                    </Row>
                  )}

                  {contestDetails?.halla_type === "event" && (
                    <Row className="form-group align-items-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Range
                      </label>
                      <Col sm={10}>
                        <h5>{contestDetails?.radius || "-"}</h5>
                      </Col>
                    </Row>
                  )}

                  {contestDetails?.private === 1 && (
                    <Row className="form-group align-items-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Private code
                      </label>
                      <Col sm={10}>
                        <h5>{contestDetails?.private_code || "-"}</h5>
                      </Col>
                    </Row>
                  )}

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Platform
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.platform}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Social Sharing
                    </label>
                    <Col sm={10}>
                      <h5>
                        {contestDetails?.allow_sharing === 0
                          ? "No"
                          : contestDetails?.allow_sharing === 1
                          ? "Yes"
                          : "-" || "-"}
                      </h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Social Tag
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.tag || "-"}</h5>
                    </Col>
                  </Row>

                  <Row className="form-group align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Voting
                    </label>
                    <Col sm={10}>
                      <h5>{contestDetails?.voting || "-"}</h5>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ContestDetails;
