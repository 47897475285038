import {
  SUBMIT_INPUT,
  REQ_BANNER,
  BANNER_SUCCESS,
  BANNER_FAILURE,
  REQ_SELECT_BANNER,
  SELECT_BANNER_SUCCESS,
  SELECT_BANNER_FAILED,
  DELETE_SELECTED_BANNER,
  REQ_BANNER_LIST,
  BANNER_LIST_SUCCESS,
  BANNER_LIST_ERROR,
  REQ_BANNER_DELETE,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_ERROR,
  REQ_BANNER_TEXT,
  BANNER_TEXT_SUCCESS,
  BANNER_TEXT_ERROR
} from "./actionTypes";

const initialState = {
  submitInput: null,
  bannerData: null,
  bannerError: null,
  selectedBanner: null,
  selectBannerError: null,
  bannerList: null,
  bannerListErr: null,
  bannerListToggle: false,
  deleteBanner: null,
  deleteBannerErr: null,
  bannerText: null,
  bannerTextError: null,
  bannerTextToggle: false,
};

const bannerData = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_INPUT:
      state = { ...state, submitInput: action.payload };
      break;
    case REQ_BANNER:
      state = { ...state };
      break;
    case BANNER_SUCCESS:
      state = { ...state, bannerData: action.payload };
      break;
    case BANNER_FAILURE:
      state = { ...state, bannerError: action.payload };
      break;
    case REQ_SELECT_BANNER:
      state = { ...state };
      break;
    case SELECT_BANNER_SUCCESS:
      state = { ...state, selectedBanner: action.payload };
      break;
    case SELECT_BANNER_FAILED:
      state = { ...state, selectBannerError: action.payload };
      break;

      case REQ_BANNER_LIST:
      state = { ...state, bannerListToggle: true };
      break;
    case BANNER_LIST_SUCCESS:
      state = { ...state, bannerList: action.payload, bannerListToggle: false };
      break;
    case BANNER_LIST_ERROR:
      state = { ...state, bannerListErr: action.payload, bannerListToggle: false };
      break;

      case REQ_BANNER_DELETE:
        state = { ...state };
        break;
      case DELETE_BANNER_SUCCESS:
        state = { ...state, deleteBanner: action.payload };
        break;
      case DELETE_BANNER_ERROR:
        state = { ...state, deleteBannerErr: action.payload };
        break;

        case REQ_BANNER_TEXT:
        state = { ...state, bannerTextToggle: true };
        break;
      case BANNER_TEXT_SUCCESS:
        state = { ...state, bannerText: action.payload , bannerTextToggle: false};
        break;
      case BANNER_TEXT_ERROR:
        state = { ...state, bannerTextError: action.payload, bannerTextToggle: false };
        break;


    case DELETE_SELECTED_BANNER:
      state = { ...state, selectedBanner: null, selectBannerError: null, bannerError: null };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default bannerData;
