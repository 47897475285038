import React, { useEffect, useState } from "react";
import {useHistory} from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import {
  incresePage,
  decresePage,
  perPageEntries,
  reqUserPointWise,
  setZero,
  userId,
  clearError,
} from "../../store/data/actions";

import {
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Spinner,
} from "reactstrap";

const UserPointWise = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.UserData);
  const currentPage = useSelector(
    (state) => state.UserData.userPointWise?.data?.current_page
  );
  const limit = useSelector(state => state.UserData.userPointWise?.data?.limit)
  const lastPage = useSelector(
    (state) => state.UserData.userPointWise?.data?.last_page
  );
  const userPointWiseCondi = useSelector(
    (state) => state.UserData.userPointWise
  );
  const userPointWiseData = useSelector(
    (state) => state.UserData.userPointWise?.data?.data
  );
  const userPointWiseError = useSelector(
    (state) => state.UserData.userPointWiseErr
  );
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  useEffect(() => {
    dispatch(
      reqUserPointWise({ page: userData.page, limit: userData.perPage })
    );
    if (currentPage > lastPage) {
      dispatch(setZero());
    }
  }, [dispatch, userData.page, userData.perPage, currentPage, lastPage]);

  const errorHandle = () => {
    alert(userPointWiseError?.message);
    dispatch(clearError());
  };

  const pushToDetails = async (id) => {
    dispatch(userId(parseInt(id)));
    history.push(`/user-details/${id}`);
  }

  return (
    <div className="container-fluid">
      <Row className="align-items-center m-3">
        <Col>
          <Row className="align-items-center">
            <Button
              disabled={userData?.page <= 1}
              onClick={() => dispatch(decresePage(1))}
            >
              Prev.
            </Button>
            <h6 className="ml-4 mr-4">{currentPage}</h6>
            <Button
              disabled={userData?.page >= lastPage}
              onClick={() => dispatch(incresePage(1))}
            >
              Next
            </Button>
            <h6 className="ml-4 mr-4">Total Pages: {lastPage}</h6>
          </Row>
        </Col>
        <Col>
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>
              Per Page - {userData?.perPage}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => dispatch(perPageEntries(5))}>
                5
              </DropdownItem>
              <DropdownItem onClick={() => dispatch(perPageEntries(10))}>
                10
              </DropdownItem>
              <DropdownItem onClick={() => dispatch(perPageEntries(20))}>
                20
              </DropdownItem>
              <DropdownItem onClick={() => dispatch(perPageEntries(50))}>
                50
              </DropdownItem>
              <DropdownItem onClick={() => dispatch(perPageEntries(100))}>
                100
              </DropdownItem>
              <DropdownItem onClick={() => dispatch(perPageEntries(200))}>
                200
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>
      {((!userPointWiseCondi && !userPointWiseError) || (userData.page !== currentPage || userData.perPage !== limit)) && <Spinner color="dark" />}
      
      {userPointWiseError && errorHandle()}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">User Point Wise</h4>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <table id="tech-companies-1" className="table table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Points</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {userPointWiseCondi && !userPointWiseError && (
                      <tbody>
                        {((userData.page === currentPage) && (userData.perPage === limit)) &&
                          userPointWiseData.map((user) => (
                            <tr>
                              <th>{user.id || "-"}</th>
                              <th>
                                {" "}
                                <img
                                  className="mr-2"
                                  src={user.image}
                                  loading="lazy"
                                  alt="user"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                />
                              </th>
                              <th>{user.username || "-"}</th>
                              <th>{user.point || "-"}</th>
                              <th>
                                <Button outline color="info" onClick={() => pushToDetails(user.id)}>
                                  Details
                                </Button>
                              </th>
                            </tr>
                          ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPointWise;
